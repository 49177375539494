import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getProducts } from "../../actions/bomtemp/products";
import ProductForm from "./ProductForm";
import { Modal } from "bootstrap";

const ProductSearch = ({ selectedProduct, setSelectedProduct, getProducts }) => {
    const [q, setQ] = useState('');
    const [Products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const onchange = (e) => {
        setQ(e.target.value);
    }
    useEffect(() => {
        if(q.length > 0){
            setPage(1);
            getProducts(1, false, q).then(res => {
                if (res.success){
                    setProducts(res.products);
                    setNumPages()
                }
            });
        }
    }, [q]);
    useEffect(() => {
        getProducts(page, false, q).then(res => {
            if (res.success){
                setProducts(res.products);
                setNumPages(res.numPages);
            }
        });
    }, [page]);
    const product_list_items = Products?.map(product => {
        return (
            <li className="list-group-item cursor-pointer" key={product.id} title="Click to select this product" onClick={e => setSelectedProduct(product)}>{product.name}</li>
        )
    })
    const showproductform = (e) => {
        const formModal = Modal.getOrCreateInstance(document.getElementById('product-form'));
        formModal.show();
    }
    return (
        <>
            <input type="text" className="form-control" placeholder="Start typing the product name" name="q" value={q} onChange={onchange} /> 
            {
                Products.length > 0 ?
                <>
                    <ul className="mt-2 mb-2 list-group">
                        {product_list_items}
                    </ul>
                </> :
                <>
                    <p>No products with the given criteria. Try changing the conditions.</p>
                </>
            }
            <Link to="#" onClick={showproductform}>Create new product</Link>
            <ProductForm Products={Products} setProducts={setProducts} redirect={false} />
        </>
    )
}

export default connect(null, {getProducts})(ProductSearch);
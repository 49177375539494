import React, { useState } from "react";
import { connect } from "react-redux";
import { createCategory } from "../../actions/bomtemp/categories";
import { Modal } from "bootstrap";

const CategoryForm = ({ categories, setCategories, createCategory }) => {
    const [name, setName] = useState('');
    const submit = () => {
        if (name.length === 0){
            window.alert("Enter category name");
            return;
        }
        createCategory(name).then(res => {
            if (res.success){
                setCategories([...categories, res.category]);
                setName('');
                const formModal = Modal.getOrCreateInstance(document.getElementById('category-form'));
                formModal.hide();
            }
            else{
                window.alert("Seems this category is already present. Please check");
            }
        })
    }
    return (
        <>
            <div className="modal fade w-100" data-bs-keyboard="false" id="category-form" tabindex="-1" aria-labelledby="category-formLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create Product Category</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="categoryNameInput">Category Name</label>
                                    <input type="text" id="categoryNameInput" name="name" onChange={e => setName(e.target.value)} value={name} className="form-control w-100" />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light bg-light text-black" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={submit}>Save</button>
                        </div>
                    </div>
                </div>
             </div>
        </>
    )
}

export default connect(null, { createCategory })(CategoryForm);
import React from "react";

const PageHeader = (props) => {
  return (
    <div className="page-header">
      <div className="container">
        <h1 className="page-title mb-0">{props.children}</h1>
      </div>
    </div>
  );
};

export default PageHeader;

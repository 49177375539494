import React from "react";
import { usePageMeta } from "../reducers/pagemetaReducer";
import PageHeader from "../components/page/PageHeader";
import Breadcrumb from "../components/page/Breadcrumb";

const DeliveryPolicy = () => {
  const pageMeta = usePageMeta();
  const breadcrumbItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Shipping & Returns",
      url: "",
    },
  ];
  return (
    <main className="main">
      <PageHeader>Shipping & Returns</PageHeader>
      <Breadcrumb items={breadcrumbItems} />
      <div className="container">
        <h2>Shipping and Returns Policy</h2>

        <h3>Shipping Policy</h3>

        <h4>Order Processing</h4>
        <p>
          Orders are processed within 1-2 business days after payment
          confirmation. Orders placed on weekends or public holidays will be
          processed on the next business day.
        </p>

        <h4>Shipping and Handling</h4>
        <p>
          We offer free shipping on all orders above ₹5999. For orders below
          ₹5999, a nominal shipping fee will be applied at checkout. Our
          shipping partners are reliable and ensure that your order reaches you
          in perfect condition.
        </p>

        <h4>Tracking Your Order</h4>
        <p>
          Once your order is shipped, you will receive an email with the
          tracking details. You can track your order using the tracking number
          provided in the email.
        </p>

        <h4>Delivery Locations</h4>
        <p>
          We deliver to most locations within the country. However, certain
          remote areas may have longer delivery timelines or may not be
          serviceable.
        </p>

        <h4>Delivery Timelines</h4>
        <p>
          <strong>Standard Delivery:</strong> Minimum Delivery Time: 5 business
          days, Maximum Delivery Time: 14 business days
        </p>
        <p>
          <strong>Express Delivery:</strong> Minimum Delivery Time: 2 business
          days, Maximum Delivery Time: 7 business days
        </p>

        <h4>Delays</h4>
        <p>
          While we strive to meet the stated delivery timelines, there may be
          unforeseen circumstances such as natural disasters, strikes, or other
          external factors that could cause delays. In case of any delay, we
          will notify you via email with an updated delivery timeline.
        </p>

        <h3>Returns Policy</h3>

        <h4>Return Eligibility</h4>
        <p>
          Our policy lasts 10 days. If 10 days have gone by since your purchase,
          unfortunately, we can’t offer you a refund or exchange. To be eligible
          for a return, your item must be unused and in the same condition that
          you received it. It must also be in the original packaging.
        </p>

        <h4>Refunds</h4>
        <p>
          Once your return is received and inspected, we will proceed with the
          refund or exchange as per your choice. If you are approved, your
          refund will be processed, and a credit will automatically be applied
          to your original method of payment within a certain number of days.
        </p>
        <h4>Late or Missing Refunds</h4>
        <p>
          If you haven’t received a refund yet, first check your bank account
          again. Then contact your credit card company, it may take some time
          before your refund is officially posted. Next contact your bank. There
          is often some processing time before a refund is posted. If you’ve
          done all of this and you still have not received your refund yet,
          please contact us at{" "}
          <a href="mailto:support@apogeemattress.com">
            support@apogeemattress.com
          </a>
          .
        </p>

        <h4>Sale Items</h4>
        <p>
          Only regular priced items may be refunded, unfortunately, sale items
          cannot be refunded.
        </p>

        <h4>Exchanges</h4>
        <p>
          We only replace items if they are defective or damaged. If you need to
          exchange it for the same item, send us an email at{" "}
          <a href="mailto:support@apogeemattress.com">
            support@apogeemattress.com
          </a>
          .
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns regarding our shipping or
          returns policy, please contact our customer service team at{" "}
          <a href="mailto:support@apogeemattress.com">
            support@apogeemattress.com
          </a>
          {/* or call us at <a href="tel:+18001234567">+1 800 123 4567</a>. */}
        </p>
      </div>
    </main>
  );
};

export default DeliveryPolicy;

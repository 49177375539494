import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "bootstrap";
import { getCategories } from "../../actions/bomtemp/categories";
import { Link } from "react-router-dom";
import { addBOMLine } from "../../actions/bomtemp/products";
import MaterialSearch from "./MaterialSearch";

const BOMLineForm = ({ Product, setProduct, addBOMLine }) => {
    const [selectedMaterial, setSelectedMaterial] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const submit = () => {
        if (!selectedMaterial){
            window.alert("Select Material");
            return;
        }
        if (quantity.toString().length === 0){
            window.alert("Enter quantity");
            return;
        }
        addBOMLine(Product?.id, selectedMaterial?.id, quantity).then(res => {
            if (res.success){
                setProduct({...Product, lines: [...Product.lines, res.line]})
                const modalForm = Modal.getOrCreateInstance(document.getElementById('bom-line-form'));
                modalForm.hide();
            }
            else{
                window.alert(res.error);
            }
        });
    }
    return (
        <>
            <div className="modal fade w-100" data-bs-keyboard="false" id="bom-line-form" tabindex="-1" aria-labelledby="bom-line-formLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Line</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                
                                <div className="form-group">
                                    
                                    {
                                        selectedMaterial ?
                                        <>
                                            You have selected {selectedMaterial?.name}&nbsp;
                                            <Link to="#" onClick={e => setSelectedMaterial(false)} className="text-primary">Change?</Link>
                                        </> :
                                        <>
                                            <label>Select Material</label>
                                            <MaterialSearch setSelectedMaterial={setSelectedMaterial} />
                                        </>
                                    }
                                    
                                </div>
                                {
                                    selectedMaterial &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="bomlinequantityInput">Quantity</label>
                                            <input type="number" id="bomlinequantityInput" name="quantity" onChange={e => setQuantity(e.target.value)} value={quantity} className="form-control" />
                                            <small>Help: If the quantity is derived from a formula, enter 1. Otherwise enter how much quantity is required.</small>
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light bg-light text-black" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={submit}>Save</button>
                        </div>
                    </div>
                </div>
             </div>
        </>
    )
}

export default connect(null, { addBOMLine })(BOMLineForm);
import React from "react";
import { Link } from "react-router-dom";
import { useCategories } from "../../reducers/shop/Categories";

const MobileMenu = () => {
  const { categories } = useCategories();
  const categories_li = categories?.map((category) => {
    const child_categories = category.subCategories?.map((sub) => (
      <li key={sub.id}>
        <Link to={`/shop/${sub.slug}`}>{sub.category}</Link>
      </li>
    ));
    return (
      <li key={category.id}>
        <Link to={`/shop/${category.slug}`}>{category.category}</Link>
        {category.subCategories?.length && <ul>{child_categories}</ul>}
      </li>
    );
  });
  return (
    <>
      <div className="mobile-menu-wrapper">
        <div className="mobile-menu-overlay"></div>
        {/* End of .mobile-menu-overlay */}

        <a href="#" className="mobile-menu-close">
          <i className="close-icon"></i>
        </a>
        {/* End of .mobile-menu-close */}

        <div className="mobile-menu-container scrollable">
          <form action="#" method="get" className="input-wrapper">
            <input
              type="text"
              className="form-control"
              name="search"
              autoComplete="off"
              placeholder="Search"
              required
            />
            <button className="btn btn-search" type="submit">
              <i className="w-icon-search"></i>
            </button>
          </form>
          {/* End of Search Form */}
          <div className="tab">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a href="#main-menu" className="nav-link active">
                  Main Menu
                </a>
              </li>
              <li className="nav-item">
                <a href="#categories" className="nav-link">
                  Categories
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane active" id="main-menu">
              <ul className="mobile-menu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/shop">Shop</Link>
                </li>
                <li>
                  <Link to="/">Wishlist</Link>
                </li>
                <li>
                  <Link to="/">Compare</Link>
                </li>
                <li>
                  <Link to="/">Track order</Link>
                </li>
                <li>
                  <Link to="/">Daily deals</Link>
                </li>
                <li>
                  <Link to="/">Blog</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/login">My Account</Link>
                </li>
                {/* <li><Link to="/signin" className="login sign-in"><i
                                    className="w-icon-account"></i>Sign In / Register</Link></li> */}
              </ul>
            </div>
            <div className="tab-pane" id="categories">
              <ul className="mobile-menu">{categories_li}</ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;

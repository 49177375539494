import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getMaterials } from "../../actions/bomtemp/materials";
import MaterialForm from "../../components/bomtemp/MaterialForm";
import Breadcrumb from "../../components/page/Breadcrumb";
import PageHeader from "../../components/page/PageHeader";
import { Modal } from "bootstrap";

const MaterialsList = ({ getMaterials }) => {
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'BOM Dashboard',
            url: '/bom-dashboard'
        },
        {
            title: 'Materials',
            url: ''
        },
    ]
    const [Materials, setMaterials] = useState([]);
    const [numPages, setNumPages] = useState(0);
    const [count, setCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') === null ? 1 : parseInt(searchParams.get('page')));
    const [paginationPages, setPaginationPages] = useState([]);
    const [q, setQ] = useState('');
    const set_pagination_array = (num_pages) => {
        if (num_pages > 1){
            var array = [];
            if (currentPage - 1 > 0){
                array = [...array, currentPage - 1];
                // array.push(currentPage - 1);
            }
            array = [...array, currentPage];
            // array.push(currentPage);
            if (currentPage + 1 <= num_pages){
                array = [...array, currentPage + 1];
                // array.push(currentPage + 1);
            }
            if (array.indexOf(1) === -1)
                array = [...array, 1];
            if (array.indexOf(num_pages) === -1)
                array = [...array, num_pages];
            array.sort();
            setPaginationPages([...array]);
        }
        else{
            setPaginationPages([]);
        }
    }
    useEffect(() => {
        getMaterials(currentPage, q).then(res => {
            if (res.success){
                setMaterials(res.materials);
                setNumPages(res.numPages);
                setCount(res.count);
                set_pagination_array(res.numPages);
            }
        })
    }, [currentPage]);
    useEffect(() => {
        if (currentPage !== 1)
            setCurrentPage(1);
        else{
            getMaterials(currentPage, q).then(res => {
                if (res.success){
                    setMaterials(res.materials);
                    setNumPages(res.numPages);
                    setCount(res.count);
                    set_pagination_array(res.numPages);
                }
            })
        }
        
    }, [q]);
    let navigate = useNavigate();
    const pagination = paginationPages?.map( item => {
        var selected = currentPage === item ? 'selected' : '';
        return (
            <li className={`text-center ${selected}`}
                onClick={e => {
                    setCurrentPage(item);
                    navigate(`/bom-dashboard/materials/?page=${item}`, {replace: false});
                }}>{item}</li>
        )
    });
    const rows = Materials?.map(material => {
        return (
            <tr key={material.id}>
                <td>{material.name}</td>
                <td>{material.formula_display}</td>
                <td>{material.std_width}</td>
                <td>{material.density}</td>
            </tr>
        )
    })
    const showmaterialform = (e) => {
        const modalForm = Modal.getOrCreateInstance(document.getElementById('material-form'));
        modalForm.show();
    }
    return (
        <>
            <main className="main">
                <PageHeader>Materials</PageHeader>
                <Breadcrumb items={breadcrumbItems} />
                <div className="page-content pt-2">
                    <div className="container">
                        <div className="mb-2 d-flex" style={{justifyContent: 'space-between', alignItems: 'baseline'}}>
                            <input className="form-control" style={{maxWidth: 240 + 'px'}} name="q" placeholder="Search..." value={q} onChange={e => setQ(e.target.value)} />
                            <div className="page-actions">
                                <Link to="#" className="btn btn-primary" onClick={showmaterialform}>Create Material</Link>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <th>Material Name</th>
                                    <th>Formula</th>
                                    <th>Standard Width&nbsp;(inch)</th>
                                    <th>Density&nbsp;(kg/m<sup>3</sup>)</th>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                        </div>
                        {numPages > 0 && 
                            <div className='row mt-2 mb-2'>
                                <div className='pagination'>
                                    <ul>
                                        {currentPage > 1 && <>
                                            <li onClick={e => {setCurrentPage(currentPage - 1); setSearchParams({page: currentPage - 1})}}><i className='fa fa-chevron-left'></i></li>
                                        </>}
                                        {pagination}
                                        
                                        {numPages > currentPage &&
                                            <li onClick={e => {setCurrentPage(currentPage + 1); setSearchParams({page: currentPage + 1})}}><i className='fa fa-chevron-right'></i></li>}
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </main>
            <MaterialForm Materials={Materials} setMaterials={setMaterials} />
        </>
    )
}

export default connect(null, { getMaterials })(MaterialsList);
import axios from 'axios';
import Cookies from 'js-cookie';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';

export const createProduct = (category_id, name, thickness) => async dispatch => {
    let body = JSON.stringify({category_id, name, thickness})
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/products/create/`, body, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};

export const getProducts = (page=1, category_id=false, q='') => async dispatch => {
    let params = `page=${page}`;
    if (category_id)
        params += `&category_id=${category_id}`;
    if (q.length > 0)
        params += `&q=${q}`;
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/products/list/?${params}`, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};

export const detailProduct = (product_id) => async dispatch => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/products/detail/${product_id}/`, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};

export const addBOMLine = (product_id, material_id, quantity) => async dispatch => {
    let body = JSON.stringify({material_id, quantity})
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/products/${product_id}/lines/add/`, body, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};

export const removeBOMLine = (line_id) => async dispatch => {
    const res = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/products/lines/delete/${line_id}/`, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};


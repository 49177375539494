import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getBOMs } from "../../actions/bomtemp/bom";
import BOMForm from "../../components/bomtemp/BOMForm";
import Breadcrumb from "../../components/page/Breadcrumb";
import PageHeader from "../../components/page/PageHeader";

const BOMList = ({ getBOMs }) => {
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'BOM Dashboard',
            url: '/bom-dashboard'
        },
        {
            title: 'BOMs',
            url: ''
        },
    ]
    const [BOMs, setBOMs] = useState([]);
    const [numPages, setNumPages] = useState(0);
    const [count, setCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') === null ? 1 : parseInt(searchParams.get('page')));
    const [paginationPages, setPaginationPages] = useState([]);
    const [q, setQ] = useState('');
    const set_pagination_array = (num_pages) => {
        if (num_pages > 1){
            var array = [];
            if (currentPage - 1 > 0){
                array = [...array, currentPage - 1];
            }
            array = [...array, currentPage];
            if (currentPage + 1 <= num_pages){
                array = [...array, currentPage + 1];
            }
            if (array.indexOf(1) === -1)
                array = [...array, 1];
            if (array.indexOf(num_pages) === -1)
                array = [...array, num_pages];
            array.sort();
            setPaginationPages([...array]);
        }
        else{
            setPaginationPages([]);
        }
    }
    useEffect(() => {
        getBOMs(currentPage, false, '').then(res => {
            setBOMs(res.boms);
            setNumPages(res.numPages);
            setCount(res.count);
            set_pagination_array(res.numPages);
        });
    }, [currentPage]);
    useEffect(() => {
        if (currentPage !== 1)
            setCurrentPage(1);
        else{
            getBOMs(currentPage, false, q).then(res => {
                if (res.success){
                    setBOMs(res.boms);
                    setNumPages(res.numPages);
                    setCount(res.count);
                    set_pagination_array(res.numPages);
                }
            })
        }
        
    }, [q]);
    let navigate = useNavigate();
    const pagination = paginationPages?.map( item => {
        var selected = currentPage === item ? 'selected' : '';
        return (
            <li className={`text-center ${selected}`}
                onClick={e => {
                    setCurrentPage(item);
                    navigate(`/bom-dashboard/boms/?page=${item}`, {replace: false});
                }}>{item}</li>
        )
    });
    // const delete = (e, bom) => {
    //     if (!window.confirm("You are about to remove a bom. All details related to this will be removed. Are you sure?"))
    //         return;
        
    // }
    const rows = BOMs?.map(bom => {
        return (
            <tr key={bom.id}>
                <td>
                    {bom.number}
                </td>
                <td className="cursor-pointer" title="Click to view product details"
                    onClick={e => navigate(`/bom-dashboard/products/${bom.product.id}/`)}>
                    {bom.product.name}
                </td>
                <td>{bom.length} inch</td>
                <td>{bom.width} inch</td>
                <td>
                    {bom.product_count}
                </td>
                <td>
                    {/* <i className="fa fa-trash cursor-pointer text-danger" onClick={e => delete(e, bom)}></i> */}
                    <a title="Download material consumption sheet" href={`/api/bomtemp/export/?boms=${bom.id}`} target="_blank"><i className="fa fa-file-download text-primary cursor-pointer"></i></a>
                </td>
            </tr>
        )
    })
    return (
        <>
            <main className="main">
                <PageHeader>BOMs</PageHeader>
                <Breadcrumb items={breadcrumbItems} />
                <div className="page-content pt-2">
                    <div className="container">
                        <div className="text-end">
                            <button data-bs-toggle="modal" data-bs-target="#bom-form" className="btn btn-primary">
                                Create BOM
                            </button> 
                        </div>
                        <div className="mt-2 mb-2 table-responsive">
                            <table className="table">
                                <thead>
                                    <th>Number</th>
                                    <th>Product</th>
                                    <th>Length</th>
                                    <th>Width</th>
                                    <th>Count</th>
                                    <th></th>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                        </div>
                        {numPages > 0 && 
                            <div className='row mt-2 mb-2'>
                                <div className='pagination'>
                                    <ul>
                                        {currentPage > 1 && <>
                                            <li onClick={e => {setCurrentPage(currentPage - 1); setSearchParams({page: currentPage - 1})}}><i className='fa fa-chevron-left'></i></li>
                                        </>}
                                        {pagination}
                                        
                                        {numPages > currentPage &&
                                            <li onClick={e => {setCurrentPage(currentPage + 1); setSearchParams({page: currentPage + 1})}}><i className='fa fa-chevron-right'></i></li>}
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </main>

            <BOMForm BOMs={BOMs} setBOMs={setBOMs} />
        </>
    )
}

export default connect(null, { getBOMs })(BOMList);
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./hocs/Layout";
import Home from "./containers/Home";
import OurStory from "./containers/OurStory";
import ShopRoute from "./routes/ShopRoute";
import AccountRoute from "./routes/AccountRoute";

import { Provider } from "react-redux";
import store from "./store";
import Signin from "./containers/Signin";
import Contact from "./containers/Contact";
import BOMTempRoute from "./routes/BOMTempRoute";
import Head from "./hocs/Head";
import ScrollToTop from "./components/ScrollToTop";
import Wishlist from "./pages/Wishlist";
import Compare from "./pages/Compare";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeliveryPolicy from "./pages/DeliveryPolicy";
import Terms from "./pages/Terms";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        {/* <Routes>
                <Route path="login" element={<Login />} />
            </Routes> */}
        <Head />
        <Layout>
          <ScrollToTop />
          <Routes>
            <Route path="/login" element={<Signin />} />
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/shipping-returns" element={<DeliveryPolicy />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
          </Routes>
          <ShopRoute />
          <AccountRoute />
          <BOMTempRoute />
        </Layout>
      </Router>
    </Provider>
  );
};

export default App;

import Cookies from "js-cookie";

export const JSON_CONTENT_FORM_CONFIG = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
}

export const MULTIPART_FORM_CONFIG = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
}
import axios from 'axios';
import Cookies from 'js-cookie';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';

export const getBOMs = (page=1, product_id=false, q='') => async dispatch => {
    let params = `page=${page}`;
    if (product_id)
        params += `&product_id=${product_id}`;
    if (q.length > 0)
        params += `&q=${q}`;
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/bom/list/?${params}`, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};

export const createBOM = (product_id, length, width, number, product_count, date) => async dispatch => {
    let body = JSON.stringify({product_id, length, width, number, product_count, date})
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/bom/create/`, body, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};



import React, {useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Navigation,Zoom } from 'swiper';

const ProductGallery = (props) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const main_slider = props.product.product_media?.map((item) => {
        return (
            <SwiperSlide key={item.id}>
                <figure className="product-image">
                    <img src={item.src}
                        data-zoom-image={item.src}
                        alt={props.product.display_name} width="800" height="900" />
                </figure>
            </SwiperSlide>
        )
    });
    const thumbs_slider = props.product.product_media?.map((item) => {
        return (
            <SwiperSlide key={item.id} className="product-thumb">
                <img src={item.src}
                        alt="Product Thumb" width="800" height="900" />
            </SwiperSlide>
        )
    });
    return (
        <>
            <div className="product-gallery product-gallery-sticky">
                <Swiper className="product-single-swiper"
                    modules={[Thumbs, Navigation, Zoom]} 
                    thumbs={{ swiper: thumbsSwiper }}
                    zoom={{maxRatio: 5, minRatio: 1}}
                    navigation={{nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev"}}>
                    <div className="swiper-wrapper row cols-1 gutter-no">
                        {main_slider}
                    </div>
                    <button className="swiper-button-next"></button>
                    <button className="swiper-button-prev"></button>
                    {/* <a href="#" className="product-gallery-btn product-image-full"><i className="w-icon-zoom"></i></a> */}
                </Swiper>
                <Swiper className="product-thumbs-wrap"
                    modules={[Thumbs, Navigation]}
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper}
                    slidesPerView={4}
                    spaceBetween={10}
                    navigation={{nextEl:".swiper-button-next", prevEl:".swiper-button-prev"}}
                    >
                    <div className="product-thumbs swiper-wrapper row cols-4 gutter-sm">
                        {thumbs_slider}
                    </div>
                    <button className="swiper-button-next"></button>
                    <button className="swiper-button-prev"></button>
                </Swiper>
            </div>
        </>
    )
} 

export default ProductGallery;
import {
    SEARCH_PRODUCTS_SUCCESS,
    SEARCH_PRODUCTS_FAIL,
} from '../../actions/shop/types'
import { useSelector } from 'react-redux'

const initialState = {
    searchedProducts: []
};

export const searchedProductsReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case SEARCH_PRODUCTS_SUCCESS: 
            return {
                ...state,
                searchedProducts: payload
            }
        case SEARCH_PRODUCTS_FAIL: 
            return {
                ...state,
                searchedProducts: []
            }
        default:
            return state;
    }
}

export function useSearchedProducts(){
    return useSelector((state) => state?.searchedProducts)
}
import {
    EMAIL_SUBSCRIPTION_SUCCESS, EMAIL_SUBSCRIPTION_FAIL
} from '../../actions/emailsubscriptions/types';
import { useSelector } from 'react-redux';

const initialState = {
    is_subscribed: false
};

export const emailSubscriptionReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case EMAIL_SUBSCRIPTION_SUCCESS:
            return {...state, is_subscribed: true};
        case EMAIL_SUBSCRIPTION_FAIL:
            return state;
        default:
            return state;
    }
}

export function useIsSubscribed(){
    return useSelector((state) => state?.emailSubscription)
}
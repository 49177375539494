import {
    GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAIL
} from '../../actions/shop/types'
import { useSelector } from 'react-redux'

const initialState = {
    categories: []
};

export const categoriesReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case GET_CATEGORIES_SUCCESS: 
            return {
                ...state,
                categories: payload
            }
        case GET_CATEGORIES_FAIL: 
            return state;
        default:
            return state;
    }
}

export function useCategories(){
    return useSelector((state) => state?.categories)
}
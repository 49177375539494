import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "bootstrap";
import { getCategories } from "../../actions/bomtemp/categories";
import CategoryForm from "./CategoryForm";

const CategorySearch = ({ selectedCategory, setSelectedCategory, getCategories }) => {
    const [Categories, setCategories] = useState([]);
    useEffect(() => {
        getCategories().then(res => {
            setCategories(res.categories);
        });
    }, []);
    const category_list_items = Categories?.map(category => {
        return (
            <li className="list-group-item cursor-pointer" key={category.id} title="Click to select this category" onClick={e => setSelectedCategory(category)}>{category.name}</li>
        )
    })
    const showcategoryform = (e) => {
        const formModal = Modal.getOrCreateInstance(document.getElementById('category-form'));
        formModal.show();
    }
    return (
        <>
            {
                Categories.length > 0 ?
                <>
                    <ul className="mt-2 mb-2 list-group">
                        {category_list_items}
                    </ul>
                </> :
                <>
                    <p>No categories with the given criteria. Try changing the conditions.</p>
                </>
            }
            <Link to="#" onClick={showcategoryform}>Create new category</Link>
            <CategoryForm categories={Categories} setCategories={setCategories} />
        </>
        
    )
}

export default connect(null, {getCategories})(CategorySearch);
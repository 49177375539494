import axios from "axios"
import { JSON_CONTENT_FORM_CONFIG } from "../Config"

export const submitContactForm = async ({name, email, phone, message, recaptchaValue}) => {
    const data = JSON.stringify({name, email, phone, message, recaptchaValue});
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/support/submit-contact-form/`, data, JSON_CONTENT_FORM_CONFIG)
        return res.data
    }
    catch(err){
        return {success: false}
    }
}
import axios from 'axios';
import { EMAIL_SUBSCRIPTION_SUCCESS, EMAIL_SUBSCRIPTION_FAIL } from './types';
import Cookies from 'js-cookie';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';

export const CreateSubscription = (email) => async dispatch => {
    const body = JSON.stringify({email});
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/emailsubscriptions/create/`, body, JSON_CONTENT_FORM_CONFIG);
        if (res.data.success){
            dispatch({
                type: EMAIL_SUBSCRIPTION_SUCCESS
            });
            return {success: true};
        }
        else{
            dispatch({
                type: EMAIL_SUBSCRIPTION_FAIL
            });
            return {success: false, error: res.data.error};
        }
    }
    catch(err){
        dispatch({
            type: EMAIL_SUBSCRIPTION_FAIL
        });
        return {success: false, error: 'Something went wrong. Your request was unsuccessful.'};
    }
};

import axios from "axios";
import {
  CURRENTORDER_FETCH_SUCCESS,
  CURRENTORDER_FETCH_FAIL,
  ADDTOCART_SUCCESS,
  ADDTOCART_FAIL,
  SETCART_SUCCESS,
  SETCART_FAIL,
} from "./types";
import Cookies from "js-cookie";
import { JSON_CONTENT_FORM_CONFIG } from "../Config";

export const getCurrentOrder = () => async (dispatch) => {
  let order_id = Cookies.get("order_id");
  let params = "";
  if (order_id) {
    params += `order_id=${order_id}`;
  }
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/orders/get-cart/?${params}`,
      JSON_CONTENT_FORM_CONFIG
    );
    if (res.data.success) {
      Cookies.set("order_id", res.data.cart.id);
      dispatch({
        type: CURRENTORDER_FETCH_SUCCESS,
        payload: res.data.cart,
      });
      return res.data.cart.id;
    } else {
      dispatch({
        type: CURRENTORDER_FETCH_FAIL,
      });
      return undefined;
    }
  } catch (err) {
    dispatch({
      type: CURRENTORDER_FETCH_FAIL,
    });
    return undefined;
  }
};

export const addToCart = (cart_id, product, qty) => async (dispatch) => {
  if (cart_id === undefined) {
    cart_id = await dispatch(getCurrentOrder());
  }
  let product_id = product?.id;
  const body = JSON.stringify({ cart_id, product_id, qty });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/orders/add-to-cart/`,
      body,
      JSON_CONTENT_FORM_CONFIG
    );

    if (res.data.success) {
      // 'success': boolean
      // 'created': boolean,
      // 'deleted': boolean,
      // 'qty': number,
      // 'amount_payable': number,
      // 'line_id': number
      dispatch({
        type: ADDTOCART_SUCCESS,
        payload: {
          created: res.data.created,
          deleted: res.data.deleted,
          product: product,
          new_qty: res.data.qty,
          amount_payable: res.data.amount_payable,
          line_id: res.data.line_id,
        },
      });
    } else {
      dispatch({
        type: ADDTOCART_FAIL,
      });
    }
  } catch {
    dispatch({
      type: ADDTOCART_FAIL,
    });
  }
};

export const setCart = (cart_id, product_id, qty) => async (dispatch) => {
  if (cart_id === undefined) {
    cart_id = await dispatch(getCurrentOrder());
  }
  const body = JSON.stringify({ cart_id, product_id, qty });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/orders/set-cart/`,
      body,
      JSON_CONTENT_FORM_CONFIG
    );
    if (res.data.success) {
      dispatch({
        type: SETCART_SUCCESS,
        payload: res.data.cart,
      });
    } else {
      dispatch({
        type: SETCART_FAIL,
      });
    }
  } catch {
    dispatch({
      type: SETCART_FAIL,
    });
  }
};

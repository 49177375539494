import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getMaterials } from "../../actions/bomtemp/materials";
import { getProducts } from "../../actions/bomtemp/products";
import MaterialsList from "../../containers/bomtemp/MaterialsList";
import { Modal } from "bootstrap";
import MaterialForm from "./MaterialForm";

const MaterialSearch = ({ selectedMaterial, setSelectedMaterial, getMaterials }) => {
    const [q, setQ] = useState('');
    const [Materials, setMaterials] = useState([]);
    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const onchange = (e) => {
        setQ(e.target.value);
    }
    useEffect(() => {
        if(q.length > 0){
            setPage(1);
            getMaterials(1, q).then(res => {
                if (res.success){
                    setMaterials(res.materials);
                    setNumPages()
                }
            });
        }
    }, [q]);
    useEffect(() => {
        getMaterials(page, q).then(res => {
            if (res.success){
                setMaterials(res.materials);
                setNumPages(res.numPages);
            }
        });
    }, [page]);
    const material_list_items = Materials?.map(material => {
        return (
            <li className="list-group-item cursor-pointer" key={material.id} title="Click to select this material" onClick={e => setSelectedMaterial(material)}>{material.name}</li>
        )
    })
    const showmaterialform = (e) => {
        const formModal = Modal.getOrCreateInstance(document.getElementById('material-form'));
        formModal.show();
    }
    return (
        <>
            <input type="text" className="form-control" placeholder="Start typing the material name" name="q" value={q} onChange={onchange} /> 
            {
                Materials.length > 0 ?
                <>
                    <ul className="mt-2 mb-2 list-group">
                        {material_list_items}
                    </ul>
                </> :
                <>
                    <p>No materials with the given criteria. Try changing the conditions.</p>
                </>
            }
            <Link to="#" onClick={showmaterialform}>Create new material</Link>
            <MaterialForm Materials={Materials} setMaterials={setMaterials} />
        </>
        
    )
}

export default connect(null, {getMaterials})(MaterialSearch);
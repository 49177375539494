import React from "react";
import { Link } from "react-router-dom";

const ProductBreadcrumb =(props) => {
    const breadcrumb_items = props.items?.map((item) => 
        <li><Link to={item.url}>{item.title}</Link></li>
    );
    return (
        <nav className="breadcrumb-nav container">
            <ul className="breadcrumb bb-no">
                {breadcrumb_items}
            </ul>
            <ul className="product-nav list-style-none">
                {props.previous && props.previous !== undefined && <li className="product-nav-prev">
                    <Link to={`/shop/${props.previous.category.slug}/${props.previous.slug}`}>
                        <i className="w-icon-angle-left"></i>
                    </Link>
                    <span className="product-nav-popup">
                        <img src={props.previous.thumbnail_url} alt={props.previous.display_name} width="110"
                            height="110" />
                        <span className="product-name">{props.previous.display_name}</span>
                    </span>
                </li>}
                {props.next && props.next !== undefined && <li className="product-nav-next">
                    <Link to={`/shop/${props.next.category.slug}/${props.next.slug}`}>
                        <i className="w-icon-angle-right"></i>
                    </Link>
                    <span className="product-nav-popup">
                        <img src={props.next.thumbnail_url} alt={props.next.display_name} width="110"
                            height="110" />
                        <span className="product-name">{props.next.display_name}</span>
                    </span>
                </li>}
            </ul>
        </nav>
    )
}

export default ProductBreadcrumb;
import React from "react";
import { Link } from "react-router-dom";

const ProductListItem = ({ product, cartButton=true, wishlistButton=true, quickviewButton=true, compareButton=true, horizontalAction=false }) => {
    let srcFirst = product?.product_media?.[0]?.src || 'https://www.apogeemattress.com/media/3_e12C6SB.png';
    let srcSecond = product?.product_media?.[1]?.src || undefined;
    const price_range = product?.min_mrp !== product?.max_mrp ? "₹" + product?.min_mrp + ' - ' + "₹" + product?.max_mrp : "₹" + product?.max_mrp;
  return (
    <div className="product-wrap">
    <div className="product text-center">
        <figure className="product-media">
            <Link to={`/shop/${product?.category?.slug}/${product?.slug}`}>
                <img src={srcFirst} alt={product?.display_name}
                    width={300} height={338} />
                {
                    srcSecond !== undefined && 
                    <img src={srcSecond} alt={product?.display_name}
                        width={300} height={338} />
                }
            </Link>
            <div className={horizontalAction ? "product-action-horizontal" : "product-action-vertical"}>
                { cartButton && <Link to="#" className="btn-product-icon btn-cart w-icon-cart"
                    title="Add to cart"></Link> }
                { wishlistButton && <Link to="#" className="btn-product-icon btn-wishlist w-icon-heart"
                    title="Add to wishlist"></Link>}
                { quickviewButton && <Link to="#" className="btn-product-icon btn-quickview w-icon-search"
                    title="Quickview"></Link>}
                { compareButton && <Link to="#" className="btn-product-icon btn-compare w-icon-compare"
                    title="Add to Compare"></Link>}
            </div>
            {/* <div className="product-label-group">
                <label className="product-label label-new">New</label>
                <label className="product-label label-discount">-35%</label>
            </div> */}
        </figure>
        <div className="product-details">
            <h4 className="product-name"><Link to={`/shop/${product?.category?.slug}/${product?.slug}`}>{product?.display_name}</Link></h4>
            {/* <div className="ratings-container">
                <div className="ratings-full">
                    <span className="ratings" style={{width: "100%"}}></span>
                    <span className="tooltiptext tooltip-top"></span>
                </div>
                <Link to={`/shop/${product?.category?.slug}/${product?.slug}`} className="rating-reviews">(3 Reviews)</Link>
            </div> */}
            <div className="product-price">
                <ins className="new-price">{price_range}</ins>
                {/* <ins className="new-price">$40.00</ins><span className="old-price">$49.89</span> */}
            </div>
        </div>
    </div>
    </div>
  )
}

export default ProductListItem
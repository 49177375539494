import axios from "axios";
import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  SEARCH_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS_FAIL,
} from "./types";
import Cookies from "js-cookie";
import { JSON_CONTENT_FORM_CONFIG } from "../Config";

export const GetProducts = async (
  ids = [],
  page = 1,
  categories = [],
  per_page = 9
) => {
  let params = `page=${page}&per_page=${per_page}`;
  if (ids) {
    params += `&ids=${JSON.stringify(ids)}`;
  }
  if (categories) {
    const filtered_categories = categories.filter(
      (cat) => cat.slug !== undefined
    );
    params += `&categories=${JSON.stringify(
      filtered_categories.map((cat) => cat.slug)
    )}`;
  }
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/catalog/get-products/?${params}`,
      JSON_CONTENT_FORM_CONFIG
    );
    return res.data;
  } catch (err) {
    return {
      success: false,
    };
  }
};

export const getProductDetail = async (slug) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/catalog/product-detail/${slug}/`,
      JSON_CONTENT_FORM_CONFIG
    );
    return res.data;
  } catch (err) {
    return {
      success: false,
    };
  }
};

export const getVariant = async (slug, configurations) => {
  let queryParams = `configurations=${JSON.stringify(
    configurations.map((item) => item?.selected?.id)
  )}`;
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/catalog/get-variant/${slug}/?${queryParams}`,
      JSON_CONTENT_FORM_CONFIG
    );
    return res.data;
  } catch (err) {
    return {
      success: false,
    };
  }
};

export const searchProducts = async (q, category = "") => {
  let queryParams = `q=${q}`;
  if (category.length > 0) {
    queryParams += `&category=${category}`;
  }
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/catalog/search/?${queryParams}`,
      JSON_CONTENT_FORM_CONFIG
    );
    return res.data;
  } catch (err) {
    return {
      success: false,
    };
  }
};

import React, { useState } from 'react';

const ParallaxFloatImage = ({src, alt, height, width}) => {
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        setOffset({
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY,
        });
    };

    const handleMouseLeave = () => {
        setOffset({ x: 0, y: 0 });
    };
    return (
        <div className='float-item' style={{width: "100%", height: "100%", position: "relative"}} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
            <img src={src} alt={alt} height={height} width={width}
                style={{
                    transition: "transform 0.2s ease",
                    transform: `translate(${offset.x / 25}px, ${offset.y / 50}px)`,
                  }} />
        </div>
    )
}

export default ParallaxFloatImage
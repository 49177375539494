import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { update_page_meta } from "../actions/page/header";
import Breadcrumb from "../components/page/Breadcrumb";
import PageHeader from "../components/page/PageHeader";
import { usePageMeta } from "../reducers/pagemetaReducer";

const CompareField = ({ field }) => {
  return <div className="compare-col compare-field">{field}</div>;
};

const Compare = ({ update_page_meta }) => {
  const pageMeta = usePageMeta();
  useEffect(() => {
    update_page_meta({
      ...pageMeta,
      title: "Compare | Apogee Mattress",
      description:
        "Inspiring a sleep revolution by educating people about the impact of a holistic sleep with support, comfort and luxury",
      opengraph: {},
      twitter: {},
    });
  }, []);
  const breadcrumbItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Compare",
      url: "",
    },
  ];
  const compareItems = [];
  const rows = compareItems?.map((item) => {
    return <></>;
  });
  return (
    <main className="main">
      <PageHeader>Compare</PageHeader>
      <Breadcrumb items={breadcrumbItems} />
      <div className="page-content mb-10 pb-2">
        <div className="container">
          {compareItems.length > 0 ? (
            <div className="compare-table">
              {/* thumbnail row start */}
              <div className="compare-row cols-xl-5 cols-lg-4 cols-md-3 cols-2 compare-products">
                <div className="compare-col compare-field">Product</div>
                <div className="compare-col compare-product">
                  <a href="#" className="btn remove-product">
                    <i className="w-icon-times-solid"></i>
                  </a>
                  <div className="product text-center">
                    <figure className="product-media">
                      <a href="product-default.html">
                        <img
                          src="assets/images/products/elements/1.jpg"
                          alt="Product"
                          width="228"
                          height="257"
                        />
                      </a>
                      <div className="product-action-vertical">
                        <a
                          href="#"
                          className="btn-product-icon btn-cart w-icon-cart"
                        ></a>
                        <a
                          href="#"
                          className="btn-product-icon btn-wishlist w-icon-heart"
                        ></a>
                      </div>
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <a href="product-default.html">
                          Electronics Black Wrist Watch
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="compare-col compare-product">
                  <a href="#" className="btn remove-product">
                    <i className="w-icon-times-solid"></i>
                  </a>
                  <div className="product text-center">
                    <figure className="product-media">
                      <a href="product-default.html">
                        <img
                          src="assets/images/products/elements/2.jpg"
                          alt="Product"
                          width="228"
                          height="257"
                        />
                      </a>
                      <div className="product-action-vertical">
                        <a
                          href="#"
                          className="btn-product-icon btn-cart w-icon-cart"
                        ></a>
                        <a
                          href="#"
                          className="btn-product-icon btn-wishlist w-icon-heart"
                        ></a>
                      </div>
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <a href="product-default.html">Summer Sport Shoes</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="compare-col compare-product">
                  <a href="#" className="btn remove-product">
                    <i className="w-icon-times-solid"></i>
                  </a>
                  <div className="product text-center">
                    <figure className="product-media">
                      <a href="product-default.html">
                        <img
                          src="assets/images/products/elements/3.jpg"
                          alt="Product"
                          width="228"
                          height="257"
                        />
                      </a>
                      <div className="product-action-vertical">
                        <a
                          href="#"
                          className="btn-product-icon btn-cart w-icon-cart"
                        ></a>
                        <a
                          href="#"
                          className="btn-product-icon btn-wishlist w-icon-heart"
                        ></a>
                      </div>
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <a href="product-default.html">Charming Design Watch</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="compare-col compare-product">
                  <a href="#" className="btn remove-product">
                    <i className="w-icon-times-solid"></i>
                  </a>
                  <div className="product text-center">
                    <figure className="product-media">
                      <a href="product-default.html">
                        <img
                          src="assets/images/products/elements/4-1.jpg"
                          alt="Product"
                          width="228"
                          height="257"
                        />
                      </a>
                      <div className="product-action-vertical">
                        <a
                          href="#"
                          className="btn-product-icon btn-cart w-icon-cart"
                        ></a>
                        <a
                          href="#"
                          className="btn-product-icon btn-wishlist w-icon-heart"
                        ></a>
                      </div>
                    </figure>
                    <div className="product-details">
                      <h3 className="product-name">
                        <a href="product-default.html">
                          Populated Gaming Mouse
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* thumbnail row end */}
              {/* price row start */}
              <div className="compare-row cols-xl-5 cols-lg-4 cols-md-3 cols-2 compare-price">
                <div className="compare-col compare-field">Price</div>
                <div className="compare-col compare-value">
                  <div className="product-price">
                    <span className="new-price">$40.00</span>
                  </div>
                </div>
                <div className="compare-col compare-value">
                  <div className="product-price">
                    <span className="new-price">$86.00</span>
                    <span className="old-price">$120.00</span>
                  </div>
                </div>
                <div className="compare-col compare-value">
                  <div className="product-price">
                    <span className="new-price">$30.00</span>
                  </div>
                </div>
                <div className="compare-col compare-value">
                  <div className="product-price">
                    <span className="new-price">$236.00</span>
                  </div>
                </div>
              </div>
              {/* price row end */}
              {/* availability row start */}
              <div className="compare-row cols-xl-5 cols-lg-4 cols-md-3 cols-2 compare-availability">
                <div className="compare-col compare-field">Availability</div>
                <div className="compare-col compare-value">In stock</div>
                <div className="compare-col compare-value">In stock</div>
                <div className="compare-col compare-value">In stock</div>
                <div className="compare-col compare-value">In stock</div>
              </div>
              {/* availability row end */}
            </div>
          ) : (
            <div className="alert alert-danger">
              You have no items to compare
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default connect(null, { update_page_meta })(Compare);

import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb =(props) => {
    const breadcrumb_items = props.items?.map((item) => 
        <li><Link to={item.url}>{item.title}</Link></li>
    );
    return (
        <nav className="breadcrumb-nav mb-10" style={props.styles}>
            <div className="container">
                <ul className="breadcrumb">
                    {breadcrumb_items}
                </ul>
            </div>
        </nav>
    )
}

export default Breadcrumb;
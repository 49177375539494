import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../components/page/MobileMenu";
import ScrollTop from "../components/page/ScrollTop";
import StickyFooter from "../components/page/StickyFooter";
import PhotoSwipe from "../components/page/PhotoSwipe";
import QuickView from "../components/page/QuickView";
import { connect } from "react-redux";
import { CreateSubscription } from "../actions/emailsubscriptions/CreateSubscription";
import { ToastContainer, toast } from "react-toastify";
import { useIsSubscribed } from "../reducers/emailsubscriptions/createsubscription";

const Footer = ({ CreateSubscription }) => {
  const { is_subscribed } = useIsSubscribed();
  const current_year = new Date().getFullYear();
  const [state, setState] = useState({
    email: "",
  });
  const success_notify = (message) => toast.success(message);
  const error_notify = (message) => toast.error(message);
  const subscribebuttonClick = (e) => {
    e.preventDefault();
    if (state.email.length > 0) {
      CreateSubscription(state.email);
    }
  };
  useEffect(() => {
    if (is_subscribed) {
      success_notify("Successfully subscribed");
      setState({ ...state, email: "" });
    }
  }, [is_subscribed]);
  const handleemailchange = (e) => {
    setState({ ...state, email: e.target.value });
  };
  return (
    <>
      <footer
        className="footer appear-animate"
        data-animation-options="{
                'name': 'fadeIn'
            }"
      >
        <div className="footer-newsletter bg-primary pt-6 pb-6">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-5 col-lg-6">
                <div className="icon-box icon-box-side text-white">
                  <div className="icon-box-icon d-inline-flex">
                    <i className="w-icon-envelop3"></i>
                  </div>
                  <div className="icon-box-content">
                    <h4 className="icon-box-title text-white text-uppercase mb-0">
                      Subscribe To Our Newsletter
                    </h4>
                    <p className="text-white">
                      Get all the latest information on Events, Sales and
                      Offers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6 col-md-9 mt-4 mt-lg-0 ">
                <form
                  method="get"
                  className="input-wrapper input-wrapper-inline input-wrapper-rounded"
                  id="newsletter-form"
                >
                  <input
                    type="email"
                    className="form-control mr-2 bg-white"
                    onChange={handleemailchange}
                    name="email"
                    id="email"
                    placeholder="Your E-mail Address"
                    value={state.email}
                  />
                  <button
                    className="btn btn-dark btn-rounded subscribe-button"
                    onClick={subscribebuttonClick}
                    type="submit"
                  >
                    Subscribe<i className="w-icon-long-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="widget widget-about">
                  <Link to="/" className="logo-footer">
                    <img
                      src="/static/assets/images/logo.webp"
                      alt="logo-footer"
                      width="100"
                      height="100"
                    />
                  </Link>
                  <div className="widget-body">
                    <p className="widget-about-title">
                      Got Question? Call us Toll Free
                    </p>
                    <a href="tel:18008907822" className="widget-about-call">
                      1800 890 7822
                    </a>
                    <p className="widget-about-desc">
                      Comfortable sleeping experience at an affordable
                      investment by keeping the product quality, innovation and
                      service excellence at its peak.
                    </p>

                    <div className="social-icons social-icons-colored">
                      <a
                        href="https://www.facebook.com/apogeemattress"
                        target="_blank"
                        className="social-icon social-facebook w-icon-facebook"
                      ></a>
                      {/* <a href="#" target="_blank" className="social-icon social-twitter w-icon-twitter"></a> */}
                      <a
                        href="https://www.instagram.com/apogeemattress/"
                        target="_blank"
                        className="social-icon social-instagram w-icon-instagram"
                      ></a>
                      <a
                        href="https://www.youtube.com/channel/UCyUh6_huUd88kVR_EGeUBTw"
                        target="_blank"
                        className="social-icon social-youtube w-icon-youtube"
                      ></a>
                      <a
                        href="https://www.pinterest.com/apogeemattress/"
                        target="_blank"
                        className="social-icon social-pinterest w-icon-pinterest"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h3 className="widget-title">Company</h3>
                  <ul className="widget-body">
                    <li>
                      <Link to="/our-story">Our Story</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    {/* <li><a href="#">Team Member</a></li>
                                        <li><a href="#">Career</a></li>
                                        <li><a href="javascript:void(0);">Contact Us</a></li>
                                        <li><a href="#">Affilate</a></li>
                                        <li><a href="#">Order History</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h4 className="widget-title">My Account</h4>
                  <ul className="widget-body">
                    <li>
                      <a href="#">My Account</a>
                    </li>
                    <li>
                      <a href="#">Orders</a>
                    </li>
                    <li>
                      <a href="#">Invoices</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="widget">
                  <h4 className="widget-title">Customer Service</h4>
                  <ul className="widget-body">
                    {/* <li><a href="#">Payment Methods</a></li>
                                        <li><a href="#">Money-back guarantee!</a></li> */}
                    <li>
                      <Link to="/shipping-returns">Shipping &amp; Returns</Link>
                    </li>
                    <li>
                      <a href="#">Support</a>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-left">
              <p className="copyright">
                Copyright © 2021 - {current_year} Apogee Mattress Private
                Limited. Developed by{" "}
                <a href="https://www.ampliquity.com" target="_blank">
                  Ampliquity Technologies
                </a>
              </p>
            </div>
            <div className="footer-right">
              {/* <span className="payment-label mr-lg-8">Payments are secured by</span> */}
              <span className="payment-label">Payments are secured by</span>
              <figure className="payment">
                <img
                  src="/static/assets/images/razorpaylogo.png"
                  alt="payment"
                  width="159"
                  height="25"
                />
              </figure>
            </div>
          </div>
        </div>
      </footer>
      <MobileMenu />
      <ScrollTop />
      <StickyFooter />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <PhotoSwipe /> */}
      {/* <QuickView /> */}
    </>
  );
};
export default connect(null, { CreateSubscription })(Footer);

import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Lazy, Pagination, Navigation, Autoplay } from 'swiper';
import ParallaxFloatImage from "../ParallaxFloatImage";
import { Link } from "react-router-dom";

const HomeIntro = () => {
    // const [offset, setOffset] = useState({ x: 0, y: 0 });

    // const handleMouseMove = (e) => {
    //     setOffset({
    //         x: e.nativeEvent.offsetX,
    //         y: e.nativeEvent.offsetY,
    //     });
    // };

    // const handleMouseLeave = () => {
    //     setOffset({ x: 0, y: 0 });
    // };
  return (
    <div className="intro-section">
        <Swiper modules={[Autoplay, Pagination, Navigation]}
            // loop={true}
            spaceBetween={0}
            speed={0}
            slidesPerView={1}
            autoplay={{
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
            }}
            className='nav-inner pg-inner'
            navigation={{nextEl:".swiper-button-next", prevEl:".swiper-button-prev"}}
            pagination={{
                el: '.swiper-pagination',
                clickable: true
            }}>
            <SwiperSlide className='banner banner-fixed intro-slide intro-slide1' 
                style={{backgroundImage: `url(${require('../../assets/images/home/sliderbg.webp')})`, backgroundColor: "#f1f0f0"}}>
                <div className='container'>
                    <figure className='slide-image'>
                        <ParallaxFloatImage src={require('../../assets/images/home/slider1.webp')} alt="Ski" width={729} height={570} />
                    </figure>
                    <div className="banner-content text-right y-50 ml-auto">
                        <h5 className="banner-subtitle text-uppercase font-weight-bold mb-2">Premium Mattresses</h5>
                        <h3 className="banner-title ls-25 mb-6">
                            Experience the Ultimate  <span className="text-primary">Comfort and Support</span>
                        </h3>
                        {/* <p>Explore our wide range of meticulously crafted mattresses, each designed for a restful night's sleep. From luxurious memory foam to traditional innerspring, we have the perfect mattress for your unique needs.</p> */}
                        <Link to="/shop"
                            className="btn btn-dark btn-outline btn-rounded btn-icon-right">
                            Shop Now<i className="w-icon-long-arrow-right"></i>
                        </Link>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide className='banner banner-fixed intro-slide intro-slide2' style={{backgroundImage: `url(${require('../../assets/images/home/sliderbg.webp')})`, backgroundColor: "#f1f0f0"}}>
                <figure className='slide-image'>
                    <ParallaxFloatImage src={require('../../assets/images/home/slider2.webp')} alt="Ski" width={729} height={570} />
                </figure>
                <div className="banner-content text-right y-50 ml-auto">
                    <h5 className="banner-subtitle text-uppercase font-weight-bold mb-2">Limited-Time Offer!</h5>
                    <h3 className="banner-title ls-25 mb-6">Save Big on Your  <span className="text-primary">Dream Mattress</span> 
                    </h3>
                    <div className="banner-price-info font-weight-bold text-dark ls-25">Up to
                        <span className="text-primary font-weight-bolder text-uppercase ls-normal"> 40%
                            Off</span></div>
                    <p className="font-weight-normal text-default ls-25">Hurry, this offer won't last long!</p>
                    <Link to="/shop"
                        className="btn btn-dark btn-outline btn-rounded btn-icon-right">
                        Shop Now<i className="w-icon-long-arrow-right"></i></Link>
                </div>
            </SwiperSlide>
            <SwiperSlide className='banner banner-fixed intro-slide intro-slide3' style={{backgroundImage: `url(${require('../../assets/images/home/sliderbg.webp')})`, backgroundColor: "#f1f0f0"}}>
                <figure className='slide-image'>
                    <ParallaxFloatImage src={require('../../assets/images/home/slider3.webp')} alt="Ski" width={729} height={570} />
                </figure>
                <div className="banner-content text-right y-50 ml-auto">
                    <h5 className="banner-subtitle text-uppercase font-weight-bold mb-2">Sleep Worry-Free</h5>
                    <h3 className="banner-title ls-25 mb-6">Experience Our Mattresses Risk-Free with our <span className="text-primary">100-night trial</span>  
                    </h3>
                    <Link to="/shop"
                        className="btn btn-dark btn-outline btn-rounded btn-icon-right">
                        Shop Now<i className="w-icon-long-arrow-right"></i></Link>
                </div>
            </SwiperSlide>
            <div className="swiper-pagination"></div>
            <button className="swiper-button-next"></button>
            <button className="swiper-button-prev"></button>
        </Swiper>
    </div>
  )
}

export default HomeIntro;
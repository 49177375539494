import {
    IS_AUTHENTICATED, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS
} from '../../actions/accounts/types'; 
import { useSelector } from 'react-redux'

const initialState = {
    isAuthenticated: false
};

export const authReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case IS_AUTHENTICATED: 
            return {
                ...state,
                isAuthenticated: payload
            };
        case LOGIN_SUCCESS: 
            return {
                ...state,
                isAuthenticated: true
            };
        case LOGIN_FAIL: 
            return {
                ...state,
                isAuthenticated: false
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false
            };
        default:
            return state;
    }
}

export const useIsAuthenticated = () => useSelector((state) => state.auth);
import {
  CURRENTORDER_FETCH_SUCCESS,
  CURRENTORDER_FETCH_FAIL,
  ADDTOCART_SUCCESS,
  ADDTOCART_FAIL,
  SETCART_SUCCESS,
  SETCART_FAIL,
} from "../../actions/orders/types";
import { useSelector } from "react-redux";

const initialState = {
  cart: {
    id: undefined,
  },
};

export const cartReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CURRENTORDER_FETCH_SUCCESS:
      return {
        ...state,
        cart: payload,
      };
    case CURRENTORDER_FETCH_FAIL:
      return state;
    case ADDTOCART_SUCCESS:
      if (payload.deleted) {
        const updatedLineItems = state.cart?.lineItems.filter(
          (lineItem) => lineItem.id !== payload.line_id
        );
        return {
          ...state,
          cart: {
            ...state.cart,
            amount_payable: payload.amount_payable,
            lineItems: updatedLineItems,
          },
        };
      }
      if (payload.created) {
        return {
          ...state,
          cart: {
            ...state.cart,
            amount_payable: payload.amount_payable,
            lineItems: [
              ...state.cart.lineItems,
              {
                id: payload.line_id,
                product: payload.product,
                quantity: payload.new_qty,
              },
            ],
          },
        };
      } else {
        const updatedLineItems = state.cart.lineItems.map((lineItem) => {
          if (lineItem?.id === payload.line_id) {
            return {
              ...lineItem,
              quantity: payload.new_qty,
            };
          }
          return lineItem;
        });

        return {
          ...state,
          cart: {
            ...state.cart,
            amount_payable: payload.amount_payable,
            lineItems: updatedLineItems,
          },
        };
      }
    case ADDTOCART_FAIL:
      return state;
    case SETCART_SUCCESS:
      return {
        ...state,
        cart: payload,
      };
    case SETCART_FAIL:
      return state;

    default:
      return state;
  }
};

export function useCart() {
  return useSelector((state) => state?.cart);
}

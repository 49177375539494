import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import CartDropDownItem from "./CartDropDownItem";
import { useCart } from "../../reducers/orders/currentOrder";
import { useLocation } from "react-router-dom";

const CartDropdown = ({}) => {
  const { cart } = useCart();
  console.log(cart);
  const lines = cart?.lineItems?.map((line) => {
    return <CartDropDownItem line={line} />;
  });
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const redirect = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.closest(".cart-dropdown").classList.remove("opened");
    if (pathname !== url) navigate(url, { replace: false });
  };
  return (
    <>
      <div className="dropdown cart-dropdown cart-offcanvas mr-0 mr-lg-2">
        <div className="cart-overlay"></div>
        <a href="#" className="cart-toggle label-down link">
          <i className="w-icon-cart">
            <span className="cart-count">
              {cart?.lineItems ? cart?.lineItems?.length : 0}
            </span>
          </i>
          <span className="cart-label">Cart</span>
        </a>
        <div className="dropdown-box">
          <div className="cart-header">
            <span>Shopping Cart</span>
            <a
              href="#"
              className="btn-close"
              style={{ background: "none", opacity: 1, width: "auto" }}
            >
              Close<i className="w-icon-long-arrow-right"></i>
            </a>
          </div>

          {/* <ComingSoon /> */}

          <div className="products">{lines}</div>

          <div className="cart-total">
            <label>Total:</label>
            <span className="price">₹ {cart?.amount_payable}</span>
          </div>

          <div className="cart-action">
            <Link
              to=""
              onClick={(e) => redirect(e, "/cart")}
              className="btn btn-dark btn-outline btn-rounded"
            >
              View Cart
            </Link>
            <Link
              to=""
              onClick={(e) => redirect(e, "/checkout")}
              className="btn btn-primary  btn-rounded"
            >
              Checkout
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {})(CartDropdown);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { update_page_meta } from "../actions/page/header";
import Breadcrumb from "../components/page/Breadcrumb";
import PageHeader from "../components/page/PageHeader";
import { usePageMeta } from "../reducers/pagemetaReducer";

const Item = ({ item }) => {
    const handleremoveitem = (item) => {}
    const handleaddtocart = (item) => {}
    return (
        <tr>
            <td className="product-thumbnail">
                <div className="p-relative">
                    <Link exact to={`/shop/${item?.product?.category?.slug}/${item.product.slug}`} href="product-default.html">
                        <figure>
                            <img src={item?.product?.thumbnail_url} alt={item.product?.display_name} width="300"
                                height="338" />
                        </figure>
                    </Link>
                    <button type="submit" className="btn btn-close" onClick={e => handleremoveitem(item)}><i
                            className="fas fa-times"></i></button>
                </div>
            </td>
            <td className="product-name">
                <Link to={`/shop/${item.product.category.slug}/${item.product.slug}`}>
                    {item.product.display_name}
                </Link>
            </td>
            <td className="product-price">
                <ins className="new-price">₹{item.product.mrp / 100}</ins>
            </td>
            <td className="wishlist-action">
                <div className="d-lg-flex">
                    {/* <a href="#"
                        className="btn btn-quickview btn-outline btn-default btn-rounded btn-sm mb-2 mb-lg-0">Quick
                        View</a> */}
                    <Link to="#" onClick={e => handleaddtocart(item)} className="btn btn-dark btn-rounded btn-sm ml-lg-2 btn-cart">Add to
                        cart</Link>
                </div>
            </td>
        </tr>
    )
}

const Wishlist = ({ update_page_meta }) => {
    const pageMeta = usePageMeta();
    useEffect(() => {
        update_page_meta({
            ...pageMeta, 
            title: 'Wishlist | Apogee Mattress', 
            description: 'Inspiring a sleep revolution by educating people about the impact of a holistic sleep with support, comfort and luxury',
            opengraph:{},
            twitter:{}
        })
    }, []);
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'Wishlist',
            url: ''
        }
    ]
    const wishlistItems = [];
    const rows = wishlistItems?.map(item => {
        return <Item item={item}/>
    })
    return (
        <main className="main wishlist-page">
            <PageHeader>Wishlist</PageHeader>
            <Breadcrumb items={breadcrumbItems} />
            <div className="page-content mb-5">
                <div className="container">
                    <h3 className="wishlist-title">My wishlist</h3>
                    <table className="shop-table wishlist-table">
                        <thead>
                            <tr>
                                <th className="product-name"><span>Product</span></th>
                                <th className="product-price"><span>Price</span></th>
                                <th className="wishlist-action">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                            {wishlistItems.length === 0 && 
                            <tr>
                                <td colSpan={3} className="text-center">You have no items in wishlist!</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    )
}

export default connect(null, { update_page_meta })(Wishlist);
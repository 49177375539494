import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { deleteCategory, getCategories } from "../../actions/bomtemp/categories";
import CategoryForm from "../../components/bomtemp/CategoryForm";
import Breadcrumb from "../../components/page/Breadcrumb";
import PageHeader from "../../components/page/PageHeader";

const CategoriesList = ({ getCategories, deleteCategory }) => {
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'BOM Dashboard',
            url: '/bom-dashboard'
        },
        {
            title: 'Categories',
            url: ''
        },
    ]
    const [Categories, setCategories] = useState([]);
    useEffect(() => {
        getCategories().then(res => {
            setCategories(res.categories);
        })
    }, []);
    let navigate = useNavigate();
    const deletecategory = (e, category_id) => {
        if (!window.confirm("You are about to delete a category. All products in this category will be removed permanently. All data related to deleted products and BOM will be permanently lost. Are you sure?"))
            return;
        deleteCategory(category_id).then(res => {
            if (res.success){
                setCategories(Categories.filter(item => item.id !== category_id));
            }
        })
    }
    const rows = Categories?.map(category => {
        return (
            <tr key={category.id}>
                <td title="Click to view products" className="cursor-pointer"
                    onClick={e => navigate(`/bom-dashboard/categories/${category.id}/`, {replace: false})}>
                        {category.name}
                </td>
                <td>
                    <i className="fa fa-trash text-danger cursor-pointer" onClick={e => deletecategory(e,category.id)}></i>
                </td>
            </tr>
        )
    })
    return (
        <>
            <main className="main">
                <PageHeader>Categories</PageHeader>
                <Breadcrumb items={breadcrumbItems} />
                <div className="page-content pt-2 mb-10">
                    <div className="container">
                        <div className="text-end">
                            <button data-bs-toggle="modal" data-bs-target="#category-form" className="btn btn-primary">
                                Create Category
                            </button> 
                        </div>
                        <div className="table-responsive mt-2">
                            <table className="table">
                                <thead>
                                    <th>Name</th>
                                    <th></th>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>

            <CategoryForm categories={Categories} setCategories={setCategories} />
        </>
    )
}

export default connect(null, { getCategories, deleteCategory })(CategoriesList);
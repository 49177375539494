import React from "react";
import { Link } from "react-router-dom";
import ProductPrice from "./ProductPrice";

const ProductItem = (props) => {
  const thumbnailUri =
    props.product.product_media?.[0]?.src ||
    "https://www.apogeemattress.com/media/3_e12C6SB.png";
  return (
    <>
      <div className="product-wrap">
        <div className="product text-center" data-id="{props.product.id}">
          <figure className="product-media">
            <Link
              to={`/shop/${props.product.category.slug}/${props.product.slug}`}
            >
              <img
                src={thumbnailUri}
                alt={props.product.display_name}
                width="300"
                height="338"
              />
            </Link>
            {/* <div className="product-action-horizontal">
                            <a href="#" className="btn-product-icon btn-cart w-icon-cart"
                                title="Add to cart"></a>
                            <a href="#" className="btn-product-icon btn-wishlist w-icon-heart"
                                title="Wishlist"></a>
                            <a href="#" className="btn-product-icon btn-compare w-icon-compare"
                                title="Compare"></a>
                            <Link to="#" onClick={handleQuickviewClick} className="btn-product-icon btn-quickview w-icon-search"
                                title="Quick View"></Link>
                        </div> */}
          </figure>
          <div className="product-details">
            <div className="product-cat">
              <Link to={`/shop/${props.product.category.slug}`}>
                {props.product.category.category}
              </Link>
            </div>
            <h3 className="product-name">
              <Link
                to={`/shop/${props.product.category.slug}/${props.product.slug}`}
              >
                {props.product.display_name}
              </Link>
            </h3>
            {/* <div className="ratings-container">
                            <div className="ratings-full">
                                <span className="ratings" style={{width: "100%"}}></span>
                                <span className="tooltiptext tooltip-top"></span>
                            </div>
                            <a href="/shop/{props.product.category.slug}/{props.product.slug}" className="rating-reviews">(3 reviews)</a>
                        </div> */}
            <div className="product-pa-wrapper">
              <ProductPrice product={props.product} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductItem;

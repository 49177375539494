import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Link,
  NavLink,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { getProductDetail, getVariant } from "../actions/shop/GetProducts";
import ProductBreadcrumb from "../components/page/ProductBreadCrumb";
import AddToCartForm from "../components/product/AddToCartForm";
import CustomerReviewTab from "../components/product/CustomerReviewTab";
import DescriptionTab from "../components/product/DescriptionTab";
import FrequentlyBoughtTogether from "../components/product/FrequentlyBoughtTogether";
import ProductGallery from "../components/product/ProductGallery";
import RelatedProducts from "../components/product/RelatedProducts";
import SpecificationTab from "../components/product/SpecificationTab";
import Swatch from "../components/product/Swatch";
import VendorTab from "../components/product/VendorTab";
import ProductPrice from "../components/shop/ProductPrice";
import { useProducts } from "../reducers/shop/Products";
import { update_page_meta } from "../actions/page/header";
import { usePageMeta } from "../reducers/pagemetaReducer";

const Product = ({ update_page_meta }) => {
  const { productSlug } = useParams();
  const [product, setProduct] = useState(null);
  const [initiating, setInitiating] = useState(true); // state variable to track page loading status
  const pageMeta = usePageMeta();
  useEffect(() => {
    setInitiating(true);
    getProductDetail(productSlug).then((response) => {
      if (response.success) {
        setProduct(response.product);
      }
      setInitiating(false);
    });
  }, [productSlug]);
  useEffect(() => {
    update_page_meta({
      ...pageMeta,
      title: `${product?.display_name} | ${product?.category?.category} | Apogee Mattress`,
      description:
        "Inspiring a sleep revolution by educating people about the impact of a holistic sleep with support, comfort and luxury",
      opengraph: {},
      twitter: {},
    });
  }, [product]);

  const [SelectedConfiguration, setSelectedConfiguration] = useState([]);
  const handleswatchclick = (e, configuration, option) => {
    e.preventDefault();
    e.stopPropagation();
    // Create a copy of the current SelectedConfiguration
    const updatedSelectedConfiguration = [...SelectedConfiguration];

    // Check if a configuration with the same name already exists in SelectedConfiguration
    const existingConfigurationIndex = updatedSelectedConfiguration.findIndex(
      (item) => item.configuration.name === configuration.name
    );

    // If it exists, update the selected option for that configuration
    if (existingConfigurationIndex !== -1) {
      updatedSelectedConfiguration[existingConfigurationIndex].selected =
        option;
    } else {
      // If it doesn't exist, add a new entry for this configuration
      updatedSelectedConfiguration.push({
        configuration: configuration,
        selected: option,
      });
    }

    // Update the state with the updated SelectedConfiguration
    setSelectedConfiguration(updatedSelectedConfiguration);
  };

  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isFetching, setIsFetching] = useState(false); // state variable to track variant fetching status
  useEffect(() => {
    // Check if all configurations are selected
    if (
      product?.configurations?.length > 0 &&
      SelectedConfiguration.length > 0
    ) {
      const allConfigurationsSelected = product?.configurations?.every((item) =>
        SelectedConfiguration?.some(
          (selectedItem) =>
            selectedItem.configuration.name === item.name &&
            selectedItem.selected !== null // Check if an option is selected
        )
      );

      if (allConfigurationsSelected && !isFetching) {
        // If all configurations are selected and not currently fetching, fetch the variant
        // Set fetching status to true
        setIsFetching(true);
        getVariant(product.slug, SelectedConfiguration).then((result) => {
          setIsFetching(false);
          if (result.success) setSelectedVariant(result.variant);
          else setSelectedVariant(null);
        });
      } else {
        setSelectedVariant(null);
      }
    } else {
      setSelectedVariant(null);
    }
  }, [SelectedConfiguration]);

  // const handleswatchclick = () => {}

  const swatches = product?.configurations?.map((swatch) => {
    const swatchType = swatch.swatch_type.toLowerCase();
    const children = swatch.options.map((option) => {
      let active = SelectedConfiguration?.find(
        (item) => item?.selected?.id === option?.id
      )
        ? "active"
        : "";
      return (
        <Link
          to="#"
          onClick={(e) => handleswatchclick(e, swatch, option)}
          className={`${swatchType} swatch-item ${active}`}
        >
          {option.option}
        </Link>
      );
    });
    return (
      <Swatch key={swatch.id} swatch_type={swatchType} name={swatch.name}>
        {children}
      </Swatch>
    );
  });

  const cleanconfigurations = () => {
    setSelectedConfiguration([]);
  };

  const breadcrumbItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Shop",
      url: "/shop",
    },
    {
      title: `${product?.display_name}`,
      url: "",
    },
  ];

  return (
    <>
      {/* Setup an activity indicator on initiating */}
      {product && (
        <main className="main mb-10 pb-1">
          <ProductBreadcrumb
            items={breadcrumbItems}
            next={undefined}
            previous={undefined}
          />
          {/* Start of Page Content */}
          <div className="page-content">
            <div className="container">
              <div className="row gutter-lg">
                <div className="main-content">
                  <div className="product product-single row">
                    <div className="col-md-6 mb-6">
                      <ProductGallery product={product} />
                    </div>
                    <div className="col-md-6 mb-4 mb-md-6">
                      <div
                        className="product-details"
                        data-sticky-options="{'minWidth': 767}"
                      >
                        <h1 className="product-title">
                          {product.display_name}
                        </h1>
                        <div className="product-bm-wrapper">
                          {/* <figure className="brand">
                                                    <img src="https://portotheme.com/html/wolmart/assets/images/products/brand/brand-1.jpg" alt="Brand"
                                                        width="102" height="48" />
                                                </figure> */}
                          <div className="product-meta">
                            <div className="product-categories">
                              Category:
                              <span className="product-category">
                                <Link to={`/shop/${product?.category?.slug}`}>
                                  {" "}
                                  {product?.category?.category}
                                </Link>
                              </span>
                            </div>
                            {/* <div className="product-sku">
                                                        SKU: <span>MS46891340</span>
                                                    </div> */}
                          </div>
                        </div>

                        <hr className="product-divider" />

                        <ProductPrice product={product} />

                        {/* <div className="ratings-container">
                                                <div className="ratings-full">
                                                    <span className="ratings" style={{width: "80%"}}></span>
                                                    <span className="tooltiptext tooltip-top"></span>
                                                </div>
                                                <a href="#product-tab-reviews" className="rating-reviews scroll-to">(3
                                                    Reviews)</a>
                                            </div> */}

                        {/* <div className="product-short-desc">
                                                <ul className="list-type-check list-style-none">
                                                    <li>Ultrices eros in cursus turpis massa cursus mattis.</li>
                                                    <li>Volutpat ac tincidunt vitae semper quis lectus.</li>
                                                    <li>Aliquam id diam maecenas ultricies mi eget mauris.</li>
                                                </ul>
                                            </div> */}

                        <hr className="product-divider" />

                        {swatches}

                        {SelectedConfiguration?.length > 0 && (
                          <Link
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              cleanconfigurations();
                            }}
                            className="product-variation-clean"
                          >
                            Clean All
                          </Link>
                        )}
                        {selectedVariant !== null && (
                          <>
                            <div className="product-variation-price">
                              <span>{`₹${selectedVariant?.salePrice}`}</span>
                            </div>
                          </>
                        )}
                        <AddToCartForm variant={selectedVariant} />

                        {/* <div className="social-links-wrapper">
                                                <div className="social-links">
                                                    <div className="social-icons social-no-color border-thin">
                                                        <a href="#" className="social-icon social-facebook w-icon-facebook"></a>
                                                        <a href="#" className="social-icon social-twitter w-icon-twitter"></a>
                                                        <a href="#"
                                                            className="social-icon social-pinterest fab fa-pinterest-p"></a>
                                                        <a href="#" className="social-icon social-whatsapp fab fa-whatsapp"></a>
                                                        <a href="#"
                                                            className="social-icon social-youtube fab fa-linkedin-in"></a>
                                                    </div>
                                                </div>
                                                <span className="divider d-xs-show"></span>
                                                <div className="product-link-wrapper d-flex">
                                                    <a href="#"
                                                        className="btn-product-icon btn-wishlist w-icon-heart"><span></span></a>
                                                    <a href="#"
                                                        className="btn-product-icon btn-compare btn-icon-left w-icon-compare"><span></span></a>
                                                </div>
                                            </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="">
                    {product?.description?.length > 0 && (
                      <>
                        <h3>About This Product</h3>
                        <p>
                          {product?.description
                            .split("\r\n")
                            .map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                      </>
                    )}
                  </div>
                  {/* <FrequentlyBoughtTogether /> */}
                  {/* <div className="tab tab-nav-boxed tab-nav-underline product-tabs">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item">
                                            <Link to="#" className="nav-link active">Description</Link>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#product-tab-specification" className="nav-link">Specification</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#product-tab-vendor" className="nav-link">Vendor Info</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#product-tab-reviews" className="nav-link">Customer Reviews (3)</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <DescriptionTab product={product} />
                                        <SpecificationTab product={product} />
                                        <VendorTab product={product} />
                                        <CustomerReviewTab product={product} />
                                    </div>
                                </div> */}

                  {/* <RelatedProducts product={product} /> */}
                </div>
                {/* End of Main Content */}
                {/* <aside className="sidebar product-sidebar sidebar-fixed right-sidebar sticky-sidebar-wrapper">
                                <div className="sidebar-overlay"></div>
                                <a className="sidebar-close" href="#"><i className="close-icon"></i></a>
                                <a href="#" className="sidebar-toggle d-flex d-lg-none"><i className="fas fa-chevron-left"></i></a>
                                <div className="sidebar-content scrollable">
                                    <div className="sticky-sidebar">
                                        <div className="widget widget-icon-box mb-6">
                                            <div className="icon-box icon-box-side">
                                                <span className="icon-box-icon text-dark">
                                                    <i className="w-icon-truck"></i>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">Free Shipping &amp; Returns</h4>
                                                    <p>For all orders over $99</p>
                                                </div>
                                            </div>
                                            <div className="icon-box icon-box-side">
                                                <span className="icon-box-icon text-dark">
                                                    <i className="w-icon-bag"></i>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">Secure Payment</h4>
                                                    <p>We ensure secure payment</p>
                                                </div>
                                            </div>
                                            <div className="icon-box icon-box-side">
                                                <span className="icon-box-icon text-dark">
                                                    <i className="w-icon-money"></i>
                                                </span>
                                                <div className="icon-box-content">
                                                    <h4 className="icon-box-title">Money Back Guarantee</h4>
                                                    <p>Any back within 30 days</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="widget widget-banner mb-9">
                                            <div className="banner banner-fixed br-sm">
                                                <figure>
                                                    <img src="https://portotheme.com/html/wolmart/assets/images/shop/banner3.jpg" alt="Banner" width="266"
                                                        height="220" style={{backgroundColor: "#1D2D44"}} />
                                                </figure>
                                                <div className="banner-content">
                                                    <div className="banner-price-info font-weight-bolder text-white lh-1 ls-25">
                                                        40<sup className="font-weight-bold">%</sup><sub
                                                            className="font-weight-bold text-uppercase ls-25">Off</sub>
                                                    </div>
                                                    <h4
                                                        className="banner-subtitle text-white font-weight-bolder text-uppercase mb-0">
                                                        Ultimate Sale</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="widget widget-products">
                                            <div className="title-link-wrapper mb-2">
                                                <h4 className="title title-link font-weight-bold">More Products</h4>
                                            </div>

                                            <div className="swiper nav-top">
                                                <div className="swiper-container swiper-theme nav-top" data-swiper-options="{
                                                    'slidesPerView': 1,
                                                    'spaceBetween': 20,
                                                    'navigation': {
                                                        'prevEl': '.swiper-button-prev',
                                                        'nextEl': '.swiper-button-next'
                                                    }
                                                }">
                                                    <div className="swiper-wrapper">
                                                        <div className="widget-col swiper-slide">
                                                            <div className="product product-widget">
                                                                <figure className="product-media">
                                                                    <a href="#">
                                                                        <img src="https://portotheme.com/html/wolmart/assets/images/shop/13.jpg" alt="Product"
                                                                            width="100" height="113" />
                                                                    </a>
                                                                </figure>
                                                                <div className="product-details">
                                                                    <h4 className="product-name">
                                                                        <a href="#">Smart Watch</a>
                                                                    </h4>
                                                                    <div className="ratings-container">
                                                                        <div className="ratings-full">
                                                                            <span className="ratings" style={{width: "100%"}}></span>
                                                                            <span className="tooltiptext tooltip-top"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-price">$80.00 - $90.00</div>
                                                                </div>
                                                            </div>
                                                            <div className="product product-widget">
                                                                <figure className="product-media">
                                                                    <a href="#">
                                                                        <img src="https://portotheme.com/html/wolmart/assets/images/shop/14.jpg" alt="Product"
                                                                            width="100" height="113" />
                                                                    </a>
                                                                </figure>
                                                                <div className="product-details">
                                                                    <h4 className="product-name">
                                                                        <a href="#">Sky Medical Facility</a>
                                                                    </h4>
                                                                    <div className="ratings-container">
                                                                        <div className="ratings-full">
                                                                            <span className="ratings" style={{width: "80%"}}></span>
                                                                            <span className="tooltiptext tooltip-top"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-price">$58.00</div>
                                                                </div>
                                                            </div>
                                                            <div className="product product-widget">
                                                                <figure className="product-media">
                                                                    <a href="#">
                                                                        <img src="https://portotheme.com/html/wolmart/assets/images/shop/15.jpg" alt="Product"
                                                                            width="100" height="113" />
                                                                    </a>
                                                                </figure>
                                                                <div className="product-details">
                                                                    <h4 className="product-name">
                                                                        <a href="#">Black Stunt Motor</a>
                                                                    </h4>
                                                                    <div className="ratings-container">
                                                                        <div className="ratings-full">
                                                                            <span className="ratings" style={{width: "60%"}}></span>
                                                                            <span className="tooltiptext tooltip-top"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-price">$374.00</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="widget-col swiper-slide">
                                                            <div className="product product-widget">
                                                                <figure className="product-media">
                                                                    <a href="#">
                                                                        <img src="https://portotheme.com/html/wolmart/assets/images/shop/16.jpg" alt="Product"
                                                                            width="100" height="113" />
                                                                    </a>
                                                                </figure>
                                                                <div className="product-details">
                                                                    <h4 className="product-name">
                                                                        <a href="#">Skate Pan</a>
                                                                    </h4>
                                                                    <div className="ratings-container">
                                                                        <div className="ratings-full">
                                                                            <span className="ratings" style={{width: "100%"}}></span>
                                                                            <span className="tooltiptext tooltip-top"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-price">$278.00</div>
                                                                </div>
                                                            </div>
                                                            <div className="product product-widget">
                                                                <figure className="product-media">
                                                                    <a href="#">
                                                                        <img src="https://portotheme.com/html/wolmart/assets/images/shop/17.jpg" alt="Product"
                                                                            width="100" height="113" />
                                                                    </a>
                                                                </figure>
                                                                <div className="product-details">
                                                                    <h4 className="product-name">
                                                                        <a href="#">Modern Cooker</a>
                                                                    </h4>
                                                                    <div className="ratings-container">
                                                                        <div className="ratings-full">
                                                                            <span className="ratings" style={{width: "80%"}}></span>
                                                                            <span className="tooltiptext tooltip-top"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-price">$324.00</div>
                                                                </div>
                                                            </div>
                                                            <div className="product product-widget">
                                                                <figure className="product-media">
                                                                    <a href="#">
                                                                        <img src="https://portotheme.com/html/wolmart/assets/images/shop/18.jpg" alt="Product"
                                                                            width="100" height="113" />
                                                                    </a>
                                                                </figure>
                                                                <div className="product-details">
                                                                    <h4 className="product-name">
                                                                        <a href="#">CT Machine</a>
                                                                    </h4>
                                                                    <div className="ratings-container">
                                                                        <div className="ratings-full">
                                                                            <span className="ratings" style={{width: "100%"}}></span>
                                                                            <span className="tooltiptext tooltip-top"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-price">$236.00</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button className="swiper-button-next"></button>
                                                    <button className="swiper-button-prev"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside> */}
              </div>
            </div>
          </div>
          {/* End of Page Content */}
        </main>
      )}
    </>
  );
};

export default connect(null, { update_page_meta })(Product);

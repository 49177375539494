import {
    GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL
} from '../../actions/shop/types'
import { useSelector } from 'react-redux'

const initialState = {
    products: []
};

export const productsReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case GET_PRODUCTS_SUCCESS: 
            return {
                ...state,
                products: payload
            }
        case GET_PRODUCTS_FAIL: 
            return state;
        default:
            return state;
    }
}

export function useProducts(){
    return useSelector((state) => state?.products)
}
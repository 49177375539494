import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/accounts/auth";

const Tabs = ({ viewMode, setViewMode, logout }) => {
    const signout = () => {
        logout();
    }
    return (
        <>
            <ul className="nav nav-tabs mb-6" role="tablist">
                <li className="nav-item">
                    <Link to="#" 
                        className={`nav-link ${viewMode === 'my-account' ? 'active' : ''}`}
                        onClick={e => setViewMode('my-account')}>Dashboard</Link>
                </li>
                <li className="nav-item">
                    <Link to="#" 
                        className={`nav-link ${viewMode === 'orders' ? 'active' : ''}`}
                        onClick={e => setViewMode('orders')}>Orders</Link>
                </li>
                <li className="nav-item">
                    <Link to="#" 
                        className={`nav-link ${viewMode === 'addresses' ? 'active' : ''}`}
                        onClick={e => setViewMode('addresses')}>Addresses</Link>
                </li>
                <li className="nav-item">
                    <Link to="#" 
                        className={`nav-link ${viewMode === 'account-details' ? 'active' : ''}`}
                        onClick={e => setViewMode('account-details')}>Account Details</Link>
                </li>
                <li className="link-item">
                    <Link to="#" className="nav-link" onClick={signout}>Logout</Link>
                </li>
            </ul>
        </>
    )
}

export default connect(null, { logout })(Tabs);
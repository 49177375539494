import React from "react";

const SectionTitle = ({ title, hasCountdown=false, countdownLabel, countdownTill, hasLink=false, linkHref, linkText }) => {
  return (
    <>
        { (hasLink || hasCountdown) ? 
        <div className="title-link-wrapper mb-3">
            <h2 className="title title-deals mb-1">{title}</h2>
            {hasCountdown && <div className="product-countdown-container font-size-sm text-dark align-items-center">
                <label>{countdownLabel}: </label>
                <div className="product-countdown countdown-compact ml-1 font-weight-bold" data-until="+10d"
                    data-relative="true" data-compact="true">10days,00:00:00</div>
            </div>}
            {hasLink && <a href={linkHref} className="font-weight-bold ls-25">{linkText}<i
                    className="w-icon-long-arrow-right"></i></a>}
        </div> : 
        <h2 className="title title-deals mb-1">{title}</h2>}
    </>
    
  )
}

export default SectionTitle
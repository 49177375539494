import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

const USPSlider = () => {
  return (
    <Swiper
        className='icon-box-wrapper br-sm mt-6 mb-10'
     slidesPerView={1}
     modules={[Autoplay]}
     breakpoints={{
        576: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 3
        },
        1200: {
            slidesPerView: 4
        },
     }}
     loop={true}>
        <SwiperSlide className='icon-box icon-box-side text-dark'>
            <span className="icon-box-icon icon-shipping">
                <i className="w-icon-truck"></i>
            </span>
            <div className="icon-box-content">
                <h4 className="icon-box-title">Quality Craftsmanship</h4>
                <p className="text-default">Expertly Made for You</p>
            </div>
        </SwiperSlide>
        <SwiperSlide className='icon-box icon-box-side text-dark'>
            <span className="icon-box-icon icon-shipping">
                <i className="w-icon-truck"></i>
            </span>
            <div className="icon-box-content">
                <h4 className="icon-box-title">Comfort Guarantee</h4>
                <p className="text-default">Sleep Sound, 100 Nights</p>
            </div>
        </SwiperSlide>
        <SwiperSlide className='icon-box icon-box-side text-dark'>
            <span className="icon-box-icon icon-shipping">
                <i className="w-icon-truck"></i>
            </span>
            <div className="icon-box-content">
                <h4 className="icon-box-title">Eco-Friendly Materials</h4>
                <p className="text-default">Sustainable and Safe Sleep</p>
            </div>
        </SwiperSlide>
        <SwiperSlide className='icon-box icon-box-side text-dark'>
            <span className="icon-box-icon icon-shipping">
                <i className="w-icon-truck"></i>
            </span>
            <div className="icon-box-content">
                <h4 className="icon-box-title">Custom Sizes</h4>
                <p className="text-default">Tailored to Your Space</p>
            </div>
        </SwiperSlide>
     </Swiper>
  )
}

export default USPSlider
import React from "react";

const ProductPrice = (props) => {
    const price_range = props.product.min_mrp !== props.product.max_mrp ? "₹" + props.product.min_mrp + ' - ' + "₹" + props.product.max_mrp : "₹" + props.product.max_mrp;
    return (
        <div className="product-price">
            <ins className="new-price">{ price_range }</ins>
        </div>

    )
}

export default ProductPrice;
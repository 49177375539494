import { SELF_USER_LOAD_SUCCESS } from "../../actions/accounts/types";
import { useSelector } from 'react-redux';

const initialState = {
    user: false
};

export const userReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case SELF_USER_LOAD_SUCCESS: 
            return {
                ...state,
                user: payload
            };
        default:
            return state;
    }
}

export const useSelfUser = () => useSelector((state) => state.user);
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCurrentOrder } from "../actions/orders/currentOrder";
import { createOrder, registerPayment } from "../actions/razorpay/razorpay";
import BillingForm from "../components/checkout/BillingForm";
import ProductList from "../components/checkout/ProductList";
import Breadcrumb from "../components/page/Breadcrumb";
import { AddLibrary } from "../hocs/Layout";
import { useCart } from "../reducers/orders/currentOrder";
import { update_page_meta } from "../actions/page/header";
import { usePageMeta } from "../reducers/pagemetaReducer";

const Checkout = ({
  createOrder,
  registerPayment,
  getCurrentOrder,
  update_page_meta,
}) => {
  const pageMeta = usePageMeta();
  useEffect(() => {
    update_page_meta({
      ...pageMeta,
      title: "Checkout | Apogee Mattress",
      description:
        "Inspiring a sleep revolution by educating people about the impact of a holistic sleep with support, comfort and luxury",
      opengraph: {},
      twitter: {},
    });
  }, []);
  const { cart } = useCart();
  const [shippingCharge, setShippingCharge] = useState(0);
  const breadcrumbItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Checkout",
      url: "",
    },
  ];
  // const [paymentOrderId, setPaymentOrderId] = useState(false);
  let paymentOrderId = false;
  let amount_payable = cart?.amount_payable;
  const options = {
    currency: "INR",
    amount: amount_payable,
    name: "Apogee Mattress",
    description:
      "Payment towards the purchase of sleep products from Apogee Mattress",
    image: "https://www.apogeemattress.com/static/assets/images/logo.webp",
    handler: function (response) {
      if (paymentOrderId) {
        registerPayment(
          paymentOrderId,
          amount_payable,
          response.razorpay_payment_id,
          response.razorpay_order_id,
          response.razorpay_signature
        ).then((res) => {
          if (res.success) {
            if (res.order_payment_complete) {
              getCurrentOrder();
            }
          } else {
          }
        });
        paymentOrderId = false;
      }
    },
    // prefill: {
    //   name: "Name",
    //   email: "email@gmail.com",
    //   contact: "9999999999",
    // },
  };
  // useEffect(() => {
  //     if (paymentOrderId){
  //         registerPayment(paymentOrderId, cart?.total, response.razorpay_payment_id, response.razorpay_order_id,response.razorpay_signature).then(res => {
  //             if (res.success){
  //                 if(res.order_payment_complete){
  //                     getCurrentOrder();
  //                 }
  //             }
  //             else {}
  //         });
  //     }
  //     setPaymentOrderId(false);
  // }, [paymentOrderId])
  const displayPayment = (e) => {
    e.preventDefault();
    e.stopPropagation();
    createOrder(cart?.id).then((res) => {
      if (res.success) {
        paymentOrderId = res.payment_order_id;
        // setPaymentOrderId(res.payment_order_id);
        options.order_id = res.razorpay_order_id;
        options.key = res.RAZORPAY_KEY_ID;
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    });
  };

  return (
    <>
      <main className="main checkout">
        {/* Start of Breadcrumb */}
        {/* <Breadcrumb items={breadcrumbItems} styles={{paddingBottom: 0 + "px !important", marginBottom: 0 + "px!important"}} /> */}
        <nav className="breadcrumb-nav">
          <div className="container">
            <ul className="breadcrumb shop-breadcrumb bb-no">
              <li className="passed">
                <Link to="/cart">Shopping Cart</Link>
              </li>
              <li className="active">
                <Link to="/checkout">Checkout</Link>
              </li>
            </ul>
          </div>
        </nav>
        {/* End of Breadcrumb */}

        {/* Start of PageContent */}
        <div className="page-content">
          <div className="container">
            {/* <div className="login-toggle">
                            Returning customer? <a href="#"
                                className="show-login font-weight-bold text-uppercase text-dark">Login</a>
                        </div>
                        <form className="login-content">
                            <p>If you have shopped with us before, please enter your details below. 
                                If you are a new customer, please proceed to the Billing section.</p>
                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="form-group">
                                        <label>Username or email *</label>
                                        <input type="text" className="form-control form-control-md" name="name"
                                            required />
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    <div className="form-group">
                                        <label>Password *</label>
                                        <input type="text" className="form-control form-control-md" name="password"
                                            required />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group checkbox">
                                <input type="checkbox" className="custom-checkbox" id="remember" name="remember" />
                                <label for="remember" className="mb-0 lh-2">Remember me</label>
                                <a href="#" className="ml-3">Lost your password?</a>
                            </div>
                            <button className="btn btn-rounded btn-login">Login</button>
                        </form> */}
            <div className="coupon-toggle">
              Have a coupon?{" "}
              <a
                href="#"
                className="show-coupon font-weight-bold text-uppercase text-dark"
              >
                Enter your code
              </a>
            </div>
            <div className="coupon-content mb-4">
              <p>If you have a coupon code, please apply it below.</p>
              <div className="input-wrapper-inline">
                <input
                  type="text"
                  name="coupon_code"
                  className="form-control form-control-md mr-1 mb-2"
                  placeholder="Coupon code"
                  id="coupon_code"
                />
                <button
                  type="submit"
                  className="btn button btn-rounded btn-coupon mb-2"
                  name="apply_coupon"
                  value="Apply coupon"
                >
                  Apply Coupon
                </button>
              </div>
            </div>
            <form className="form checkout-form" action="#" method="post">
              <div className="row mb-9">
                <div className="col-lg-7 pr-lg-4 mb-4">
                  <BillingForm />
                </div>
                <div className="col-lg-5 mb-4 sticky-sidebar-wrapper">
                  <div className="order-summary-wrapper sticky-sidebar">
                    <h3 className="title text-uppercase ls-10">Your Order</h3>
                    <div className="order-summary table-responsive">
                      <table className="order-table">
                        <thead>
                          <tr>
                            <th colspan="2">
                              <b>Products</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <ProductList />
                        </tbody>
                        <tfoot>
                          <tr className="shipping-methods">
                            <td colspan="2" className="text-left">
                              <h4 className="title title-simple bb-no mb-1 pb-0 pt-3">
                                Shipping
                              </h4>
                              <ul id="shipping-method" className="mb-4">
                                <li>
                                  <div className="custom-radio">
                                    <input
                                      type="radio"
                                      id="free-shipping"
                                      checked={true}
                                      disabled=""
                                      className="custom-control-input"
                                      name="shipping"
                                    />
                                    <label
                                      for="free-shipping"
                                      className="custom-control-label color-dark"
                                    >
                                      Free Shipping
                                    </label>
                                    <small>
                                      {" "}
                                      Special Offer! Free shipping on all
                                      orders!!!
                                    </small>
                                  </div>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr className="order-total">
                            <th>
                              <b>Total</b>
                            </th>
                            <td>
                              <b>₹ {cart.amount_payable + shippingCharge}</b>
                            </td>
                          </tr>
                        </tfoot>
                      </table>

                      <div className="payment-methods" id="payment_method">
                        <h4 className="title font-weight-bold ls-25 pb-0 mb-1">
                          Payment Methods
                        </h4>
                        <div className="accordion payment-accordion">
                          {/* <div className="card">
                                                        <div className="card-header">
                                                            <a href="#cash-on-delivery" className="collapse">Direct Bank Transfor</a>
                                                        </div>
                                                        <div id="cash-on-delivery" className="card-body expanded">
                                                            <p className="mb-0">
                                                                Make your payment directly into our bank account. 
                                                                Please use your Order ID as the payment reference. 
                                                                Your order will not be shipped until the funds have cleared in our account.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <a href="#payment" className="expand">Check Payments</a>
                                                        </div>
                                                        <div id="payment" className="card-body collapsed">
                                                            <p className="mb-0">
                                                                Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.
                                                            </p>
                                                        </div>
                                                    </div> */}
                          {/* <div className="card">
                                                        <div className="card-header">
                                                            <a href="#delivery" className="expand">Cash on delivery</a>
                                                        </div>
                                                        <div id="delivery" className="card-body collapsed">
                                                            <p className="mb-0">
                                                                Pay with cash upon delivery.
                                                            </p>
                                                        </div>
                                                    </div> */}
                          <div className="card">
                            <div className="card-header">
                              <p className="mb-0">
                                Pay with Razorpay payment gateway
                              </p>
                              {/* <a href="#razorpay" className="collapse">
                                Razorpay
                              </a> */}
                            </div>
                            {/* <div id="razorpay" className="card-body expanded">
                              <p className="mb-0">
                                Pay with Razorpay payment gateway
                              </p>
                            </div> */}
                          </div>
                          {/* <div className="card p-relative">
                                                        <div className="card-header">
                                                            <a href="#paypal" className="expand">Paypal</a>
                                                        </div>
                                                        <a href="https://www.paypal.com/us/webapps/mpp/paypal-popup" className="text-primary paypal-que" 
                                                            onclick="javascript:window.open('https://www.paypal.com/us/webapps/mpp/paypal-popup','WIPaypal',
                                                            'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); 
                                                            return false;">What is PayPal?
                                                        </a>
                                                        <div id="paypal" className="card-body collapsed">
                                                            <p className="mb-0">
                                                                Pay via PayPal, you can pay with your credit cart if you
                                                                don't have a PayPal account.
                                                            </p>
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>

                      <div className="form-group place-order pt-6">
                        <button
                          type="submit"
                          onClick={displayPayment}
                          className="btn btn-dark btn-block btn-rounded"
                        >
                          Place Order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* End of PageContent */}
      </main>
      {AddLibrary("https://checkout.razorpay.com/v1/checkout.js")}
    </>
  );
};

export default connect(null, {
  createOrder,
  registerPayment,
  getCurrentOrder,
  update_page_meta,
})(Checkout);

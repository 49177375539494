import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "bootstrap";
import { getCategories } from "../../actions/bomtemp/categories";
import { createProduct } from "../../actions/bomtemp/products";
import CategoryForm from "./CategoryForm";
import { Link, useNavigate } from "react-router-dom";
import CategorySearch from "./CategorySearch";

const ProductForm = ({ Products, setProducts, redirect=true, createProduct }) => {
    const [name, setName] = useState('');
    const [thickness, setThickness] = useState(4);
    const [selectedCategory, setSelectedCategory] = useState('');
    let navigate = useNavigate();
    const submit = () => {
        if (selectedCategory.length === 0){
            window.alert("Select Category");
            return;
        }
        if (name.length === 0){
            window.alert("Enter Product name");
            return;
        }
        if (thickness.length === 0){
            window.alert("Enter Product thickness");
            return;
        }
        createProduct(selectedCategory, name, thickness).then(res => {
            if (res.success){
                const productForm = Modal.getOrCreateInstance(document.getElementById('product-form'));
                productForm.hide();
                if (redirect)
                    navigate(`/bom-dashboard/products/${res.product.id}/`, {replace: false});
                else
                    setProducts([...Products, res.product])
            }
        })
    }
    return (
        <>
            <div className="modal fade w-100" data-bs-backdrop="static" data-bs-keyboard="false" id="product-form" tabindex="-1" aria-labelledby="product-formLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create Product</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                {
                                    !selectedCategory ? 
                                    <CategorySearch selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} /> :
                                    <p>You have selected <b>{selectedCategory.name}</b>&nbsp;<Link to="#" onClick={e => setSelectedCategory(false)}>Change?</Link></p>
                                }
                                {
                                    selectedCategory &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="productNameInput">Product Name</label>
                                            <input type="text" id="productNameInput" name="name" onChange={e => setName(e.target.value)} value={name} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="productThicknessInput">Thickness</label>
                                            <input type="number" id="productThicknessInput" name="thickness" onChange={e => setThickness(e.target.value)} value={thickness} className="form-control" />
                                        </div>
                                    </>
                                }
                                
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light bg-light text-black" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={submit}>Save</button>
                        </div>
                    </div>
                </div>
             </div>
        </>
    )
}

export default connect(null, { createProduct })(ProductForm);
import {
    GET_VARIETIES_SUCCESS, GET_VARIETIES_FAIL
} from '../../actions/references/types'
import { useSelector } from 'react-redux'

const initialState = {
    varieties: []
};

export const varietiesReducer = (state=initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case GET_VARIETIES_SUCCESS: 
            return {
                ...state,
                varieties: payload
            }
        case GET_VARIETIES_FAIL: 
            return state;
        default:
            return state;
    }
}

export function useVarieties(){
    return useSelector((state) => state?.varieties)
}
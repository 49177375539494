
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import ProductListItem from './ProductListItem'

const ProductSlider = ({ products }) => {
    if (products.length === 0)
        return null;
  return (
    <Swiper spaceBetween={20} slidesPerView={2} breakpoints={{
        576: {
            slidesPerView: 3
        },
        768: {
            slidesPerView: 4
        },
        992: {
            slidesPerView: 5
        }
    }}>
        {
            products.map(product => {
                return (
                    <SwiperSlide>
                        <ProductListItem 
                            product={product} 
                            cartButton={false} 
                            wishlistButton={false} 
                            quickviewButton={false}
                            compareButton={false} />
                    </SwiperSlide>
                )
            })
        }
    </Swiper>
  )
}

export default ProductSlider
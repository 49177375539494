import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BOMList from '../containers/bomtemp/BOMList';
import BOMTemp from '../containers/bomtemp/BOMTemp';
import CategoriesList from '../containers/bomtemp/CategoriesList';
import CategoryDetail from '../containers/bomtemp/CategoryDetail';
import MaterialDetail from '../containers/bomtemp/MaterialDetail';
import MaterialsList from '../containers/bomtemp/MaterialsList';
import ProductDetail from '../containers/bomtemp/ProductDetail';
import ProductsList from '../containers/bomtemp/ProductsList';


export default class BOMTempRoute extends React.Component{
    render(){
        return (
            <Routes>
                <Route path='/bom-dashboard/' element={<BOMTemp/>} />
                <Route path='/bom-dashboard/boms/' element={<BOMList/>} />
                <Route path='/bom-dashboard/materials/' element={<MaterialsList/>} />
                <Route path='/bom-dashboard/materials/:material_id/' element={<MaterialDetail/>} />
                <Route path='/bom-dashboard/products/' element={<ProductsList/>} />
                <Route path='/bom-dashboard/products/:product_id/' element={<ProductDetail/>} />
                <Route path='/bom-dashboard/categories/' element={<CategoriesList/>} />
                <Route path='/bom-dashboard/categories/:category_id/' element={<CategoryDetail/>} />
            </Routes>
            
        )
    }
}
import axios from 'axios';
import Cookies from 'js-cookie';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';
import {
    IS_AUTHENTICATED,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_FAIL,
    LOGOUT_SUCCESS,
} from "./types";

export const checkAuthenticated = () => async dispatch => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/is_authenticated/`, JSON_CONTENT_FORM_CONFIG);
    dispatch({
        type: IS_AUTHENTICATED,
        payload: res.data.is_authenticated
    });
};

export const authenticate = (email, password) => async dispatch => {
    const body = JSON.stringify({email, password});
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/login/`, body, JSON_CONTENT_FORM_CONFIG);
    if (res.data.success){
        dispatch({
            type: LOGIN_SUCCESS
        });
    }
    else{
        dispatch({
            type: LOGIN_FAIL
        });
    }
};

export const logout = () => async dispatch => {
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/logout/`, {}, JSON_CONTENT_FORM_CONFIG);
    if (res.data.success){
        dispatch({
            type: LOGOUT_SUCCESS
        });
    }
};

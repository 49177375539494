import React from "react";
import { Route, Routes } from "react-router-dom";
import Cart from "../containers/Cart";
import Checkout from "../containers/Checkout";
import Product from "../containers/Product";
import Shop from "../containers/Shop";

export default class ShopRoute extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:categorySlug" element={<Shop />} />
        <Route path="/shop/:categorySlug/:productSlug" element={<Product />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
      </Routes>
    );
  }
}

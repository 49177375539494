import { configureStore } from '@reduxjs/toolkit';
import { emailSubscriptionReducer } from './reducers/emailsubscriptions/createsubscription';
import { cartReducer } from './reducers/orders/currentOrder';
import { varietiesReducer } from './reducers/references/Varieties';
import { categoriesReducer } from './reducers/shop/Categories';
import { productsReducer } from './reducers/shop/Products';
import { searchedProductsReducer } from './reducers/shop/searchedProducts';
import { authReducer } from './reducers/accounts/auth';
import { userReducer } from './reducers/accounts/user';
import { pagemetaReducer } from './reducers/pagemetaReducer';

const reducer = {
    // page
    page: pagemetaReducer,

    // Accounts
    auth: authReducer,
    user: userReducer,

    //EmailSubscription
    emailSubscription: emailSubscriptionReducer,

    // Cart Reducer
    cart: cartReducer,

    varieties: varietiesReducer,

    categories: categoriesReducer,

    products: productsReducer,

    searchedProducts: searchedProductsReducer,
  }
  
  const store = configureStore({
    reducer: reducer
  })
  
  export default store;
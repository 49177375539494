import React from "react";
import { Link } from "react-router-dom";

const ScrollTop = () => {
  return (
    <>
      <Link
        id="scroll-top"
        className="scroll-top"
        to="#top"
        title="Top"
        role="button"
      >
        <i className="w-icon-angle-up"></i>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 70 70"
        >
          <circle
            id="progress-indicator"
            fill="transparent"
            stroke="#000000"
            strokeMiterlimit="10"
            cx="35"
            cy="35"
            r="34"
            style={{ strokeDasharray: "16.4198, 400" }}
          ></circle>
        </svg>
      </Link>
    </>
  );
};

export default ScrollTop;

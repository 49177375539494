import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NewsletterPopup from "../components/page/NewsletterPopup";
import { usePageMeta } from "../reducers/pagemetaReducer";
import { update_page_meta } from "../actions/page/header";
import { connect } from "react-redux";
import HomeIntro from "../components/home/HomeIntroSlider";
import USPSlider from "../components/USPSlider";
import SectionTitle from "../components/SectionTitle";
import BestDeals from "../components/BestDeals";
import CategorySlider from "../components/CategorySlider";
import ProductSlider from "../components/ProductSlider";
import { GetProducts } from "../actions/shop/GetProducts";

// Home page
const Home = ({ update_page_meta }) => {
  const pageMeta = usePageMeta();
  const [showProducts, setShowProducts] = useState([]);
  useEffect(() => {
    GetProducts(
      [
        "apogee-ultima",
        "apogee-classic-sustain",
        "apogee-luxe-expert",
        "apogee-rathi-romance",
        "apogee-legend-eco-ortho",
      ],
      1,
      [],
      5
    ).then((res) => {
      if (res.success) {
        setShowProducts(res.products);
      }
    });
  }, []);
  useEffect(() => {
    update_page_meta({
      ...pageMeta,
      title: "Buy Best Mattresses Online | Apogee Mattress",
      description:
        "Inspiring a sleep revolution by educating people about the impact of a holistic sleep with support, comfort and luxury",
      opengraph: {},
      twitter: {},
    });
  }, []);
  // document.body.classList.add('home');
  document.body.classList.add("about-us");

  return (
    <>
      <main className="main">
        <HomeIntro />
        <div className="container">
          <USPSlider />
          <div className="row category-cosmetic-lifestyle mb-5">
            <div className="col-md-6 mb-4">
              <div className="banner banner-fixed category-banner-1 br-xs">
                <figure>
                  <img
                    src={require("../assets/images/categories/mattress_category.webp")}
                    alt="mattress"
                    width="610"
                    height="200"
                    style={{ backgroundColor: "#3B4B48" }}
                  />
                </figure>
                <div className="banner-content y-50 pt-1">
                  <h5 className="banner-subtitle font-weight-bold text-uppercase">
                    Unveiling
                  </h5>
                  <h3 className="banner-title font-weight-bolder text-capitalize text-white">
                    The Mattress
                    <br />
                    Of Your Dreams
                  </h3>
                  <Link
                    to="/shop/mattress"
                    className="btn btn-white btn-link btn-underline btn-icon-right"
                  >
                    Shop Now<i className="w-icon-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="banner banner-fixed category-banner-2 br-xs">
                <figure>
                  <img
                    src={require("../assets/images/categories/pillows.webp")}
                    alt="Pillows Banner"
                    width="610"
                    height="200"
                    style={{ backgroundColor: "#E5E5E5" }}
                  />
                </figure>
                <div className="banner-content y-50 pt-1">
                  <h5 className="banner-subtitle font-weight-bold text-uppercase">
                    Touch & Feel
                  </h5>
                  <h3 className="banner-title font-weight-bolder text-capitalize">
                    Range Of
                    <br />
                    Pillows
                  </h3>
                  <Link
                    to="/shop/pillows"
                    className="btn btn-dark btn-link btn-underline btn-icon-right"
                  >
                    Shop Now<i className="w-icon-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <SectionTitle
            title="Our Products"
            hasCountdown={false}
            countdownLabel="Offer Ends in"
            countdownTill={new Date()}
            hasLink={true}
            linkHref="/shop"
            linkText="More Products"
          />
          <ProductSlider products={showProducts} />
          {/* <BestDeals /> */}
        </div>
        <section className="boost-section pt-10 pb-10">
          <div className="container mt-10 mb-9">
            <div className="row align-items-center mb-10">
              <div className="col-md-6 mb-8">
                <figure className="br-lg">
                  <img
                    src="/static/assets/images/about/2.png"
                    alt="Banner"
                    width="610"
                    height="450"
                    style={{ backgroundColor: "#9E9DA2" }}
                  />
                </figure>
              </div>
              <div className="col-md-6 pl-lg-8 mb-8">
                <h4 className="title text-left">
                  Contributing towards the global goal of living life in a
                  sustainable way
                </h4>
                <p className="mb-6">
                  Apogee is happy to introduce a factory-to-home model that
                  ensures our products are not only cost effective for our
                  consumers but also manufactured under our expert observation
                  leaving no room for any ambiguity and promising absolute
                  transparency in the purchase cycle starting from purchase
                  right up to delivery. <br />
                  Our objective is to educate people and empower them to make
                  the right decision for themselves as our products will be
                  their sleep-in partners and hence it becomes critical for them
                  to make an informed decision. The impact of a good night’s
                  sleep on one’s health and its benefits are ignored and this is
                  what we are here to revolutionize.
                </p>
                <Link to="/shop" className="btn btn-dark btn-rounded">
                  Visit Our Store
                </Link>
              </div>
            </div>

            {/* <AwardsWrapper /> */}
          </div>
        </section>
        {/* <div className='container'>
                    
                </div> */}
      </main>
    </>
  );
};
export default connect(null, { update_page_meta })(Home);

import React, { useState } from "react";
import { connect } from "react-redux";
import { useCart } from "../../reducers/orders/currentOrder";

const ProductList = ({}) => {
  const { cart } = useCart();
  const lines = cart.lineItems?.map((line) => {
    return (
      <>
        <tr className="bb-no">
          <td className="product-name">
            {line.product.display_name} <i className="fas fa-times"></i>{" "}
            <span className="product-quantity">{line.quantity}</span>
          </td>
          <td className="product-total">
            ₹ {line.product.salePrice * line.quantity}
          </td>
        </tr>
      </>
    );
  });

  return (
    <>
      {lines}
      <tr className="cart-subtotal bb-no">
        <td>
          <b>Subtotal</b>
        </td>
        <td>
          <b>₹ {cart.amount_payable}</b>
        </td>
      </tr>
    </>
  );
};

export default connect(null, {})(ProductList);

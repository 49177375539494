import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ProductForm from "../../components/bomtemp/ProductForm";
import Breadcrumb from "../../components/page/Breadcrumb";
import PageHeader from "../../components/page/PageHeader";
import { getProducts } from '../../actions/bomtemp/products';
import { Modal } from "bootstrap";

const ProductsList = ({ getProducts }) => {
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'BOM Dashboard',
            url: '/bom-dashboard'
        },
        {
            title: 'Products',
            url: ''
        },
    ]
    const [Products, setProducts] = useState([]);
    const [numPages, setNumPages] = useState(0);
    const [count, setCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') === null ? 1 : parseInt(searchParams.get('page')));
    const [paginationPages, setPaginationPages] = useState([]);
    const [q, setQ] = useState('');
    const set_pagination_array = (num_pages) => {
        if (num_pages > 1){
            var array = [];
            if (currentPage - 1 > 0){
                array = [...array, currentPage - 1];
                // array.push(currentPage - 1);
            }
            array = [...array, currentPage];
            // array.push(currentPage);
            if (currentPage + 1 <= num_pages){
                array = [...array, currentPage + 1];
                // array.push(currentPage + 1);
            }
            if (array.indexOf(1) === -1)
                array = [...array, 1];
            if (array.indexOf(num_pages) === -1)
                array = [...array, num_pages];
            array.sort();
            setPaginationPages([...array]);
        }
        else{
            setPaginationPages([]);
        }
    }
    useEffect(() => {
        getProducts(currentPage, searchParams.get('category'), q).then(res => {
            if (res.success){
                setProducts(res.products);
                setNumPages(res.numPages);
                setCount(res.count);
                set_pagination_array(res.numPages);
            }
        })
    }, [currentPage]);
    useEffect(() => {
        if (currentPage !== 1)
            setCurrentPage(1);
        else{
            getProducts(currentPage, searchParams.get('category'), q).then(res => {
                if (res.success){
                    setProducts(res.products);
                    setNumPages(res.numPages);
                    setCount(res.count);
                    set_pagination_array(res.numPages);
                }
            })
        }
        
    }, [q]);
    let navigate = useNavigate();
    const pagination = paginationPages?.map( item => {
        var selected = currentPage === item ? 'selected' : '';
        return (
            <li className={`text-center ${selected}`}
                onClick={e => {
                    setCurrentPage(item);
                    navigate(`/bom-dashboard/products/?page=${item}`, {replace: false});
                }}>{item}</li>
        )
    });
    const rows = Products?.map(product => {
        return (
            <tr key={product.id}>
                <td className="cursor-pointer" title="Click to view details"
                    onClick={e => navigate(`/bom-dashboard/products/${product.id}/`, {replace: false})}>{product.name}</td>
                <td>{product.category.name}</td>
                <td>{product.thickness}</td>
                <td>
                    <a title="Download material consumption sheet" href={`/api/bomtemp/export-product-materials/?products=${product.id}`} target="_blank"><i className="fa fa-file-download text-primary cursor-pointer"></i></a>
                </td>
            </tr>
        )
    })
    const showproductform = (e) => {
        const modalForm = Modal.getOrCreateInstance(document.getElementById('product-form'));
        modalForm.show();
    }
    return (
        <>
            <main className="main">
                <PageHeader>Products</PageHeader>
                <Breadcrumb items={breadcrumbItems} />
                <div className="page-content pt-2">
                    <div className="container">
                        <div className="mb-2 d-flex" style={{justifyContent: 'space-between', alignItems: 'baseline'}}>
                            <input className="form-control" style={{maxWidth: 240 + 'px'}} name="q" placeholder="Search..." value={q} onChange={e => setQ(e.target.value)} />
                            <div className="page-actions">
                                <Link to="#" className="btn btn-primary" onClick={showproductform}>Create Product</Link>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <th>Product Name</th>
                                    <th>Category</th>
                                    <th>Thickness</th>
                                    <th></th>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                        </div>
                        {numPages > 0 && 
                            <div className='row mt-2 mb-2'>
                                <div className='pagination'>
                                    <ul>
                                        {currentPage > 1 && <>
                                            <li onClick={e => {setCurrentPage(currentPage - 1); setSearchParams({page: currentPage - 1})}}><i className='fa fa-chevron-left'></i></li>
                                        </>}
                                        {pagination}
                                        
                                        {numPages > currentPage &&
                                            <li onClick={e => {setCurrentPage(currentPage + 1); setSearchParams({page: currentPage + 1})}}><i className='fa fa-chevron-right'></i></li>}
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </main>
            <ProductForm Products={Products} setProducts={setProducts} />
        </>
    )
}

export default connect(null, { getProducts })(ProductsList);
import axios from 'axios';
import Cookies from 'js-cookie';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';

export const getMaterials = (page=1, q='') => async dispatch => {
    let params = `page=${page}`;
    if (q.length > 0)
        params += `&q=${q}`;
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/materials/list/?${params}`, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};

export const getMaterialFormulae = () => async dispatch => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/materials/formulae/list/`, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};

export const createMaterial = (name, formula, std_width, density) => async dispatch => {
    let body = JSON.stringify({name, formula, std_width, density});
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/materials/create/`, body, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { searchProducts } from "../actions/shop/GetProducts";
import CartDropdown from "../components/cart/CartDropdown";
import { useCategories } from "../reducers/shop/Categories";
import { useSearchedProducts } from "../reducers/shop/searchedProducts";

const Header = () => {
  const [CartCount, setCartCount] = useState(0);
  const [cartSubtotal, setcartSubtotal] = useState(0);
  const { categories } = useCategories();
  const { searchedProducts } = useSearchedProducts();
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  console.log(searchCategory);
  let navigate = useNavigate();

  const [line, setline] = useState({
    id: 2,
    qty: 3,
    amount: 1000,
    product: {
      id: 3,
      name: "Product name",
      slug: "slug",
      thumbnail_url: "",
      category: {
        id: 2,
        name: "cat",
        slug: "cat",
      },
    },
  });
  const categories_select = categories?.map((category) => {
    const child_categories = category.subCategories?.map((sub) => (
      <option key={sub.id} value={sub.slug}>
        {sub.category}
      </option>
    ));
    return (
      <>
        <option value={category.slug}>{category.category}</option>
        {category.subCategories.length > 0 && (
          <optgroup label={`Sub-categories of ${category.category}`}>
            {child_categories}
          </optgroup>
        )}
      </>
    );
  });
  const categories_li = categories?.map((category) => {
    const child_categories = category.subCategories?.map((sub) => {
      return (
        <li key={sub.id}>
          <Link to={`/shop/${sub.slug}`}>{sub.category}</Link>
        </li>
      );
    });
    return (
      <>
        <li
          key={category.id}
          className={category.subCategories?.length > 0 ? "has-submenu" : ""}
        >
          <Link to={`/shop/${category.slug}`}>{category.category}</Link>
          {category.subCategories.length > 0 && (
            <ul className="submenu">{child_categories}</ul>
          )}
        </li>
      </>
    );
  });
  const searchforproducts = async (e) => {
    setSearch(e.target.value);
    if (e.target.value.length > 0) {
      let response = await searchProducts(e.target.value, searchCategory);
      if (response.success) setSearchResults(response.products);
      else setSearchResults([]);
    }
  };
  const searchedProductsList = searchResults?.map((product) => {
    const thumbnailUri =
      product.product_media?.[0]?.src ||
      "https://www.apogeemattress.com/media/3_e12C6SB.png";
    return (
      <li
        onClick={(e) => {
          navigate(`/shop/${product?.category?.slug}/${product?.slug}/`, {
            replace: false,
          });
          setSearch("");
        }}
      >
        <div className="thumbnail">
          <img
            className="img-fluid"
            src={thumbnailUri}
            alt={product.display_name}
          />
        </div>
        <div className="product-info">
          <div className="name">
            {product.display_name}
            <div className="d-flex">
              <span className="category">{product.category?.category}</span>
              <span className="price">
                &#8377;{product.min_mrp} - &#8377;{product.max_mrp}
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  });
  return (
    <header className="header header-border">
      <div className="header-top">
        <div className="container">
          <div className="header-left">
            <p className="welcome-msg">
              Welcome to Apogee Mattress Private Limited!
            </p>
          </div>
          <div className="header-right">
            {/* <a href="javascript:void(0);" className="d-lg-show">Blog</a> */}
            <Link to="/contact-us" className="d-lg-show">
              Contact Us
            </Link>
            <Link to="/my-account" className="">
              <i className="w-icon-account"></i>{" "}
              <span className="d-lg-show">My Account</span>
            </Link>
            {/* {this.state.isAuthenticated}
                        <a href="javascript:void(0);" className="d-lg-show">My Account</a> */}

            {/* <Link to="/login" className="d-lg-show login sign-in"><i
                                className="w-icon-account"></i>Sign In</Link>
                        <span className="delimiter d-lg-show">/</span>
                        <Link to="/login" className="ml-0 d-lg-show login register">Register</Link> */}
          </div>
        </div>
      </div>
      {/* End of Header Top */}

      <div className="header-middle">
        <div className="container">
          <div className="header-left mr-md-4">
            <a href="#" className="mobile-menu-toggle  w-icon-hamburger"></a>
            <Link to="/" className="logo ml-lg-0">
              <img
                src="/static/assets/images/logo.webp"
                alt="logo"
                width="75"
                height="75"
              />
            </Link>
            <form
              method="get"
              className="header-search hs-expanded hs-round d-none d-md-flex input-wrapper"
            >
              <div className="select-box">
                <select
                  id="category"
                  name="category"
                  value={searchCategory}
                  onChange={(e) => setSearchCategory(e.target.value)}
                >
                  <option value="">All Categories</option>
                  {categories_select}
                </select>
              </div>
              <input
                type="text"
                className="form-control"
                name="search"
                id="search"
                value={search}
                onChange={(e) => searchforproducts(e)}
                placeholder="Search..."
                required
              />
              <button
                className="btn btn-search"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                type="submit"
              >
                <i className="w-icon-search"></i>
              </button>
              {search.length > 0 && (
                <div className="search-results-pop">
                  {searchResults?.length > 0 ? (
                    <ul>{searchedProductsList}</ul>
                  ) : (
                    <span className="m-2">No items to show!</span>
                  )}
                </div>
              )}
            </form>
          </div>
          <div className="header-right ml-4">
            <div
              className="header-call d-lg-flex align-items-center"
              //   style={{ marginRight: 0 }}
            >
              <a href="tel:#" className="w-icon-call"></a>
              <div className="call-info d-lg-show">
                <h4 className="chat font-weight-normal font-size-md text-normal ls-normal text-light mb-0">
                  Contact us :
                </h4>
                <a
                  href="tel:18008907822"
                  className="phone-number font-weight-bolder ls-50"
                >
                  1800 890 7822
                </a>
              </div>
            </div>
            {/* 
                            when activating following items
                            1. remove display: none from .header-call::after in style.min.css 
                            2. remove marginRight: 0 from the .header-call class above
                            3. add class .d-xs-show to .header-call above
                        */}
            {/* <Link className="wishlist label-down link d-xs-show" to="/wishlist">
                            <i className="w-icon-heart"></i>
                            <span className="wishlist-label d-lg-show">Wishlist</span>
                        </Link>
                        <Link className="compare label-down link d-xs-show" to="/compare">
                            <i className="w-icon-compare"></i>
                            <span className="compare-label d-lg-show">Compare</span>
                        </Link> */}
            <CartDropdown />
          </div>
        </div>
      </div>
      {/* End of Header Middle */}

      <div className="header-bottom sticky-content fix-top sticky-header">
        <div className="container">
          <div className="inner-wrap">
            <div className="header-left">
              <nav className="main-nav">
                <ul className="menu active-underline">
                  {/* <li className="active">
                                        <NavLink to="/">Home</NavLink>
                                    </li> */}
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/shop">Shop</NavLink>
                    <ul>{categories_li}</ul>
                  </li>
                  {/* <li>
                                        <NavLink to="/">Dealers</NavLink>
                                    </li> */}
                </ul>
              </nav>
            </div>
            <div className="header-right">
              {/* <a href="#" className="d-xl-show"><i className="w-icon-map-marker mr-1"></i>Track Order</a>
                            <a href="#"><i className="w-icon-sale"></i>Daily Deals</a> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default connect(null, {})(Header);

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import AwardsWrapper from "../components/about/AwardsWrapper";
import Breadcrumb from "../components/page/Breadcrumb";
import PageHeader from "../components/page/PageHeader";
import { usePageMeta } from "../reducers/pagemetaReducer";
import { update_page_meta } from "../actions/page/header";
import { connect } from "react-redux";

const OurStory = ({ update_page_meta }) => {
    document.body.classList.remove(...document.body.classList);
    document.body.classList.add('about-us');
    const pageMeta = usePageMeta();
    useEffect(() => {
        update_page_meta({
            ...pageMeta, 
            title: 'Our Story | Apogee Mattress', 
            description: 'Inspiring a sleep revolution by educating people about the impact of a holistic sleep with support, comfort and luxury',
            opengraph:{},
            twitter:{}
        })
    }, []);
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'Our Story',
            url: ''
        }
    ]
    return (
        <>
            <main className="main">
                <PageHeader>Our Story</PageHeader>
                <Breadcrumb items={breadcrumbItems} />
            
            <div className="page-content">
                <div className="container">
                    <section className="introduce mb-10 pb-10">
                        <h2 className="title title-center">
                        Inspiring a sleep revolution by educating people about the impact of a holistic
                        sleep with support, comfort and luxury.
                        </h2>
                        {/* <p className=" mx-auto text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            labore et dolore magna aliqua. Venenatis tellu metus</p> */}
                        {/* <figure className="br-lg">
                            <img src="assets/images/pages/about_us/1.jpg" alt="Banner" 
                                width="1240" height="540" style={{backgroundColor: "#D0C1AE"}} />
                        </figure> */}
                    </section>

                    <section className="customer-service mb-7">
                        <div className="row align-items-center">
                            <div className="col-md-6 pr-lg-8 mb-8">
                                <h2 className="title text-left">Brand Story</h2>
                                <div className="accordion accordion-simple accordion-plus">
                                    <div className="card border-no">
                                        <div className="card-header">
                                            <a href="#collapse3-1" className="collapse show">The Problem</a>
                                        </div>
                                        <div className="card-body expanded" id="collapse3-1">
                                            <p className="mb-0">
                                            When the work from home culture was adopted by almost everyone on the planet, the lines
between a bedroom and a work room almost diminished. Sleep was compromised, stress was
aggravated, rest and rejuvenation were as good as a dream.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <a href="#collapse3-2" className="expand show">The Eureka Moment</a>
                                        </div>
                                        <div className="card-body collapsed" id="collapse3-2">
                                            <p className="mb-0">
                                            The eureka moment occurred when the founder of our company, Mr. M S Balan, experienced all
this for himself, that’s when the dream to build Apogee was conceived. He decided that he will
upgrade the sleeping experience entirely so that people can get the rest they deserve after
spending a really hard day at work or home for that matter.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <a href="#collapse3-3" className="expand show">Foundation Metrics</a>
                                        </div>
                                        <div className="card-body collapsed" id="collapse3-3">
                                            <p className="mb-0">
                                            Apogee is driven by the dream to serve excellent quality products at an affordable investment
so that the people can claim their right to a comfortable sleeping experience.
Innovation, Technology and Commitment are the foundation metrics on which we are building
the company along with some exceptional minds who are dedicated to work towards a collective
dream.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-8">
                                <figure className="br-lg">
                                    <img src="/static/assets/images/about/1.png" alt="Banner"
                                        width="610" height="500" style={{backgroundColor: "#CECECC"}} />
                                </figure>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="boost-section pt-10 pb-10">
                    <div className="container mt-10 mb-9">
                        <div className="row align-items-center mb-10">
                            <div className="col-md-6 mb-8">
                                <figure className="br-lg">
                                    <img src={require('../assets/images/chairman.JPG')} alt="Banner"
                                        width="610" height="450" style={{backgroundColor: "#9E9DA2"}} />
                                </figure>
                            </div>
                            <div className="col-md-6 pl-lg-8 mb-8">
                                <p className="mb-6">
                                Welcome to Apogee Mattress, where we transform the way you experience sleep. We believe in providing not just a mattress, but a sanctuary of comfort and support. Quality sleep is the cornerstone of a healthy life, and it's our mission to make that accessible through our innovative sleep solutions.
                                <br /><br />
                                Our journey began with a dream, and now, we craft each mattress with precision and passion, using the finest materials and cutting-edge technology. Our commitment to quality extends beyond our products to sustainability and community engagement.
                                <br /><br />
                                Looking ahead, we remain dedicated to innovation, customer satisfaction, and sustainable growth. Thank you for choosing Apogee Mattress as your sleep partner. Your trust and loyalty are what drive us.
                                <br /><br />
                                Sincerely,
                                <br /><br />
                                M S Balan<br />
                                Managing Director, Apogee Mattress Private Limited
                                </p>
                            </div>
                        </div>

                        {/* <AwardsWrapper /> */}
                    </div>
                </section>

                {/* <section className="member-section mt-10 pt-9 mb-10 pb-4">
                    <div className="container">
                        <h4 className="title title-center mb-3">Meet Our Leaders</h4>
                        <div className="swiper-container swiper-theme" data-swiper-options="{
                            'spaceBetween': 20,
                            'slidesPerView': 1,
                            'breakpoints': {
                                '576': {
                                    'slidesPerView': 2
                                },
                                '768': {
                                    'slidesPerView': 3
                                },
                                '992': {
                                    'slidesPerView': 4
                                }
                            }
                        }">
                            <div className="swiper-wrapper row cols-xl-4 cols-lg-3 cols-sm-2 cols-1">
                                <div className="swiper-slide member-wrap">
                                    <figure className="br-lg">
                                        <img src="/static/assets/images/team/msbalan.jpeg" alt="Member" width="295" height="332" />
                                        <div className="overlay">
                                            <div className="social-icons">
                                                <Link to="/" target="_blank" className="social-icon social-facebook w-icon-facebook"></Link>
                                                <Link to="/" target="_blank" className="social-icon social-twitter w-icon-twitter"></Link>
                                                <Link to="/" target="_blank" className="social-icon social-instagram w-icon-instagram"></Link>
                                            </div>
                                        </div>
                                    </figure>
                                    <div className="member-info text-center">
                                        <h4 className="member-name">M S Balan</h4>
                                        <p className="text-uppercase">Founder &amp; CEO</p>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </section> */}
            </div>
        </main>
        </>
    )
}

export default connect(null, { update_page_meta })(OurStory);
import axios from "axios";
import { JSON_CONTENT_FORM_CONFIG } from "../Config";
import { SELF_USER_LOAD_SUCCESS, SELF_USER_LOAD_FAIL } from "./types";

export const getSelfUser = () => async dispatch => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/get-self-user/`, JSON_CONTENT_FORM_CONFIG);
    dispatch({
        type: SELF_USER_LOAD_SUCCESS,
        payload: res.data.user
    });
};
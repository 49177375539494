import React from 'react';
import { usePageMeta } from '../reducers/pagemetaReducer';
import PageHeader from '../components/page/PageHeader';
import Breadcrumb from '../components/page/Breadcrumb';

const PrivacyPolicy = () => {
    const pageMeta = usePageMeta();
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'Privacy Policy',
            url: ''
        }
    ]
  return (
    <main className="main">
        <PageHeader>Privacy Policy</PageHeader>
        <Breadcrumb items={breadcrumbItems} />
        <div className='container'>
        <p><em>Effective Date: 10th October, 2023</em></p>
    
        <p>Thank you for choosing Apogee Mattress. We value your trust and are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information. By using our website, you consent to the practices described in this policy.</p>

        <h2>Information We Collect</h2>
        <ol>
            <li>
                <strong>Personal Information</strong>: When you interact with our website, we may collect personal information such as your name, email address, phone number, and address. We collect this information when you fill out contact forms or subscribe to our newsletter.
            </li>
            <li>
                <strong>Usage Data</strong>: We may collect data on how you use our website, such as the pages you visit, the duration of your visit, your IP address, and browser type. We use this information to improve our website and services.
            </li>
        </ol>

        <h2>How We Use Your Information</h2>
        <ol>
            <li>
                <strong>Communication</strong>: We may use your personal information to communicate with you, respond to your inquiries, provide updates, and send promotional offers.
            </li>
            <li>
                <strong>Analytics</strong>: We use usage data to analyze website traffic, monitor the performance of our website, and improve user experience.
            </li>
        </ol>

        <h2>Data Security</h2>
        <p>We take the security of your data seriously and implement reasonable measures to protect it. However, no data transmission or storage system is entirely secure, and we cannot guarantee the security of your information.</p>

        <h2>Cookies</h2>
        <p>Our website may use cookies to enhance your browsing experience. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline them.</p>

        <h2>Disclosure to Third Parties</h2>
        <p>We do not sell, trade, or share your personal information with third parties for marketing purposes. However, we may share your information with service providers who assist us in operating our website and business. These third parties are contractually obligated to maintain the confidentiality of your information.</p>

        <h2>Links to Other Websites</h2>
        <p>Our website may contain links to external websites. Please note that we are not responsible for the privacy practices of these third-party websites. We encourage you to read their privacy policies.</p>

        <h2>Changes to this Privacy Policy</h2>
        <p>We reserve the right to update this Privacy Policy as needed. When we do, we will revise the "Effective Date" at the top of this page. We encourage you to periodically review this policy to stay informed about how we protect your information.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about our privacy practices, please contact us at:</p>

        <p>
            Should you have any concerns or grievances, we encourage you to reach out to our dedicated Grievance Officer at the following email address: <a href="mailto:grievances@apogeemattress.com">grievances@apogeemattress.com</a>
        </p>
        </div>
    </main>
  )
}

export default PrivacyPolicy
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "bootstrap";
import { createMaterial, getMaterialFormulae } from "../../actions/bomtemp/materials";

const MaterialForm = ({ Materials, setMaterials, createMaterial, getMaterialFormulae }) => {
    const [name, setName] = useState('');
    const [form, setForm] = useState({
        name: '',
        formula: '',
        std_width: null,
        density: null
    })
    const submit = () => {
        if (form.name.length === 0){
            window.alert("Enter category name");
            return;
        }
        createMaterial(form.name, form.formula, form.std_width, form.density).then(res => {
            if (res.success){
                setMaterials([...Materials, res.material]);
                setName('');
                const formModal = Modal.getOrCreateInstance(document.getElementById('material-form'));
                formModal.hide();
            }

        })
    }
    const [Formulae, setFormulae] = useState([]);
    useEffect(() => {
        getMaterialFormulae().then(res => {
            setFormulae(res.formulae);
        })
    }, []);
    const formula_options = Formulae?.map(formula => {
        return (
            <option value={formula.value}>{formula.display}</option>
        )
    })
    return (
        <>
            <div className="modal fade w-100" data-bs-keyboard="false" id="material-form" tabindex="-1" aria-labelledby="material-formLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create Material</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="materialNameInput">Material Name</label>
                                    <input type="text" id="materialNameInput" name="name" onChange={e => setForm({...form, name: e.target.value})} value={form.name} className="form-control w-100" />
                                </div>

                                <div className="form-group mt-2">
                                    <label htmlFor="materialformulaInput">Select Formula</label>
                                    <select className="form-control" value={form.formula} onChange={e => setForm({...form, formula: e.target.value})}>
                                        <option value="">Select a formula</option>
                                        {formula_options}
                                    </select>
                                </div>

                                <div className="form-group mt-2">
                                    <label htmlFor="materialstdwidthInput">Standard width</label>
                                    <input type="number" id="materialstdwidthInput" name="std_width" onChange={e => setForm({...form, std_width: e.target.value})} value={form.std_width} className="form-control w-100" />
                                    <smalll>Must be in inch</smalll>
                                </div>
                                <div className="form-group mt-2">
                                    <label htmlFor="materialdensityInput">Density</label>
                                    <input type="number" id="materialdensityInput" name="density" onChange={e => setForm({...form, density: e.target.value})} value={form.density} className="form-control w-100" />
                                    <small>Must be in Kilogram / Cubic meter. Will be devided by 61020 for converting it to Kilogram / Cubic inch</small>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light bg-light text-black" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={submit}>Save</button>
                        </div>
                    </div>
                </div>
             </div>
        </>
    )
}

export default connect(null, { createMaterial, getMaterialFormulae })(MaterialForm);
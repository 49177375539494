import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { GetVarieties } from "../../../actions/references/GetVarieties";
import { useVarieties } from "../../../reducers/references/Varieties";

const VarietyFilter = ({ GetVarieties }) => {
  const { varieties } = useVarieties();

  useEffect(() => {
    if (!varieties?.length) GetVarieties();
  }, []);
  const variety_li = varieties?.map((variety) => (
    <li key={variety.id}>
      <Link to={`?variety=${variety.id}`}>{variety.variety}</Link>
    </li>
  ));
  return (
    <div className="widget widget-collapsible">
      <h3 className="widget-title">
        <label>All Varieties</label>
      </h3>
      <ul className="widget-body filter-items search-ul">{variety_li}</ul>
    </div>
  );
};

export default connect(null, { GetVarieties })(VarietyFilter);

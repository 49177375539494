import React from "react";
import { useCategories } from "../../../reducers/shop/Categories";
import { Link } from "react-router-dom";

const CategoryFilter = () => {
  const { categories } = useCategories();
  if (categories.length === 0) return null;
  return (
    <div className="widget widget-collapsible">
      <h3 className="widget-title">
        <label>All Categories</label>
      </h3>

      <ul className="widget-body filter-items search-ul">
        {categories.map((category) => {
          const subCategories = category.subCategories?.map((sub) => {
            return (
              <li key={sub.id}>
                <Link to={`/shop/${sub.slug}/`}>{sub.category}</Link>
              </li>
            );
          });
          return (
            <li key={category.id}>
              <Link to={`/shop/${category.slug}/`}>{category.category}</Link>
              {category.subCategories.length > 0 && (
                <ul style={{ display: "block" }}>{subCategories}</ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CategoryFilter;

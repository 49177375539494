import React from "react";

const Swatch = (props) => {

    return (
        <div className={`product-form product-variation-form product-${props.swatch_type}-swatch`} style={{display: "block"}}>
            <label>{props.name}:</label>
            <div className="d-flex align-items-center product-variations" style={{flexWrap: "wrap"}}>
                {props.children}
            </div>
        </div>
    )
}
export default Swatch;

{/* <a href="#" className="color" style={{backgroundColor: "#ffcc01"}}></a>
                <a href="#" className="color" style={{backgroundColor: "#ca6d00"}}></a>
                <a href="#" className="color" style={{backgroundColor: "#1c93cb"}}></a>
                <a href="#" className="color" style={{backgroundColor: "#ccc"}}></a>
                <a href="#" className="color" style={{backgroundColor: "#333"}}></a> */}
{/* <div className="product-form product-variation-form product-size-swatch">
<label className="mb-1">Size:</label>
<div className="flex-wrap d-flex align-items-center product-variations">
    <a href="#" className="size">Small</a>
    <a href="#" className="size">Medium</a>
    <a href="#" className="size">Large</a>
    <a href="#" className="size">Extra Large</a>
</div>
<a href="#" className="product-variation-clean">Clean All</a>
</div> */}
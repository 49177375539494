import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/page/Breadcrumb";
import PageHeader from "../components/page/PageHeader";
import { usePageMeta } from "../reducers/pagemetaReducer";
import { update_page_meta } from "../actions/page/header";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { submitContactForm } from "../actions/support/contactform";

const Contact = ({ update_page_meta }) => {
  const pageMeta = usePageMeta();
  useEffect(() => {
    update_page_meta({
      ...pageMeta,
      title: "Contact Us | Apogee Mattress",
      description:
        "Inspiring a sleep revolution by educating people about the impact of a holistic sleep with support, comfort and luxury",
      opengraph: {},
      twitter: {},
    });
  }, []);
  const breadcrumbItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Contact Us",
      url: "",
    },
  ];
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };
  const handleFormChange = (e) => {
    e.preventDefault();
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (form.name.length === 0 || form.email.length === 0) {
      window.alert("Name and Email are required");
      return;
    }
    submitContactForm({ ...form, recaptchaValue: recaptchaValue }).then(
      (res) => {
        if (res.success)
          window.alert(
            "We have received your query. One of our team member will contact you soon."
          );
      }
    );
  };
  return (
    <>
      <main className="main">
        <PageHeader>Contact Us</PageHeader>
        <Breadcrumb items={breadcrumbItems} />

        {/* Start of PageContent */}
        <div className="page-content contact-us">
          <div className="container">
            <section className="content-title-section mb-10">
              <h3 className="title title-center mb-3">Contact Information</h3>
              {/* <p className="text-center">Lorem ipsum dolor sit amet,
                                consectetur
                                adipiscing elit, sed do eiusmod tempor incididunt ut</p> */}
            </section>
            {/* End of Contact Title Section */}

            <section className="contact-information-section mb-10">
              <div
                className=" swiper-container swiper-theme "
                data-swiper-options="{
                                'spaceBetween': 20,
                                'slidesPerView': 1,
                                'breakpoints': {
                                    '480': {
                                        'slidesPerView': 2
                                    },
                                    '768': {
                                        'slidesPerView': 3
                                    }
                                }
                            }"
              >
                <div className="swiper-wrapper row cols-xl-4 cols-md-3 cols-sm-2 cols-1">
                  <div className="swiper-slide icon-box text-center icon-box-primary">
                    <span className="icon-box-icon icon-email">
                      <i className="w-icon-envelop-closed"></i>
                    </span>
                    <div className="icon-box-content">
                      <h4 className="icon-box-title">E-mail Address</h4>
                      <p>ampl@apogeemattress.com</p>
                    </div>
                  </div>
                  <div className="swiper-slide icon-box text-center icon-box-primary">
                    <span className="icon-box-icon icon-headphone">
                      <i className="w-icon-headphone"></i>
                    </span>
                    <div className="icon-box-content">
                      <h4 className="icon-box-title">Phone Number</h4>
                      <p>1800 890 7822</p>
                    </div>
                  </div>
                  <div className="swiper-slide icon-box text-center icon-box-primary">
                    <span className="icon-box-icon icon-map-marker">
                      <i className="w-icon-map-marker"></i>
                    </span>
                    <div className="icon-box-content">
                      <h4 className="icon-box-title">Address</h4>
                      <p>
                        Plot No. 21, CNI Complex, <br />
                        Patia, Bhubaneswar, India <br />
                        751 024
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End of Contact Information section */}

            <hr className="divider mb-10 pb-1" />

            <section className="contact-section">
              <div className="row gutter-lg pb-3">
                <div className="col-lg-6 mb-8">
                  <h4 className="title mb-3">People usually ask these</h4>
                  <div className="accordion accordion-bg accordion-gutter-md accordion-border">
                    <div className="card">
                      <div className="card-header">
                        <a href="#collapse1" className="collapse show">
                          How can I track an order?
                        </a>
                      </div>
                      <div id="collapse1" className="card-body expanded">
                        <p className="mb-0">
                          You can track your order <Link to="/track">here</Link>
                          . Alternatively, we will email you the tracking
                          information of your order.
                        </p>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <a href="#collapse2" className="expand show">
                          How can I cancel my order?
                        </a>
                      </div>
                      <div id="collapse2" className="card-body collapsed">
                        <p className="mb-0">
                          You can cancel your order{" "}
                          <Link to="/my-account/orders/">here</Link>
                        </p>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <a href="#collapse3" className="expand show">
                          The amount was deducted but my order was not placed
                        </a>
                      </div>
                      <div id="collapse3" className="card-body collapsed">
                        <p className="mb-0">
                          Sometimes we don't receive a confirmation of your
                          payment from the payment gateway. This happens very
                          rarely. If the payment confirmation is received within
                          an hour, we'll verify and process your order. If the
                          payment is successful after an hour, then we'll refund
                          the amount. The refund can take upto 7-10 working days
                          to reflect in your account. In some cases the money is
                          debited from your bank account and we've not received
                          it. In such cases your bank will credit your account
                          in 48-96 hours.
                        </p>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header">
                        <a href="#collapse4" className="expand show">
                          When can I expect my order delivery?
                        </a>
                      </div>
                      <div id="collapse4" className="card-body collapsed">
                        <p className="mb-0">
                          We're happy to let you know that all of our orders are
                          dispatched within two days. We try to deliver the
                          order within 6-8 days from the day the order was
                          placed.
                          {/* You can use the pincode checker on the product page to find out the estimated 
                                                    delivery time for that product even before you choose to place the order with us. */}
                          If you've already placed your order with us, you can
                          check the estimated date of delivery of your shipment
                          by <Link to="/track">clicking here</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-8">
                  <h4 className="title mb-3">Send Us a Message</h4>
                  <form
                    className="form contact-us-form"
                    onSubmit={handleContactFormSubmit}
                    method="post"
                  >
                    <div className="form-group">
                      <label for="username">Your Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        onChange={handleFormChange}
                        value={form.name}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label for="email_1">Your Email</label>
                      <input
                        type="email"
                        id="email_1"
                        name="email"
                        onChange={handleFormChange}
                        value={form.email}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label for="email_1">Your Phone</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        onChange={handleFormChange}
                        value={form.phone}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label for="message">Your Message</label>
                      <textarea
                        id="message"
                        name="message"
                        cols="30"
                        rows="5"
                        onChange={handleFormChange}
                        value={form.message}
                        className="form-control"
                      ></textarea>
                    </div>
                    {/* <ReCAPTCHA
                                            sitekey="6LfIspsoAAAAAISBn0lk8OyJYA8gzvNqOxThZRPr"
                                            onChange={handleRecaptchaChange}
                                        /> */}
                    <button type="submit" className="btn btn-dark btn-rounded">
                      Send Now
                    </button>
                  </form>
                </div>
              </div>
            </section>
            {/* End of Contact Section */}
          </div>

          {/* Google Maps - Go to the bottom of the page to change settings and map location. */}
          {/* <div className="google-map contact-google-map" id="googlemaps"></div> */}
          {/* End Map Section */}
        </div>
        {/* End of PageContent */}
      </main>
    </>
  );
};

export default connect(null, { update_page_meta })(Contact);

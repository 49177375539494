import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart, setCart } from "../../actions/orders/currentOrder";
import { useCart } from "../../reducers/orders/currentOrder";

const CartDropDownItem = ({ line, addToCart }) => {
  const { cart } = useCart();
  const removefromcart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    addToCart(cart.id, line.product, 0 - line.quantity);
    // setCart(cart.id, line.product.id, 0);
  };

  if (line.quantity > 0) {
    return (
      <div
        className="product product-cart"
        data-id="{line.id}"
        data-product="{line.product.id}"
      >
        <div className="product-detail">
          <Link
            to={`/shop/${line.product.category.slug}/${line.product.slug}`}
            className="product-name"
          >
            {line.product.display_name}
            <br />
            {line.product.category.category}
          </Link>
          <div className="price-box">
            <span className="product-quantity">{line.quantity}</span>
            <span className="product-price">₹ {line.product.salePrice}</span>
          </div>
        </div>
        <figure className="product-media">
          <Link
            exact
            to={`/shop/${line.product.category.slug}/${line.product.slug}`}
          >
            <img
              src={line.product.product_media[0].src}
              alt={line.product.display_name}
              height="84"
              width="94"
            />
          </Link>
        </figure>
        <button
          className="btn btn-link btn-close"
          onClick={(e) => removefromcart(e)}
        >
          <i className="fas fa-times removefromcart"></i>
        </button>
      </div>
    );
  } else {
    return <></>;
  }
};

export default connect(null, { addToCart })(CartDropDownItem);

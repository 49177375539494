import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "bootstrap";
import { getCategories } from "../../actions/bomtemp/categories";
import ProductSearch from "./ProductSearch";
import { Link, useNavigate } from "react-router-dom";
import { createBOM } from "../../actions/bomtemp/bom";

const BOMForm = ({ BOMs, setBOMs, createBOM }) => {
    const [form, setForm] = useState({
        number: '',
        product_count: 1,
        length: null,
        width: null,
    })
    const [selectedProduct, setSelectedProduct] = useState(false);
    const submit = () => {
        if (!selectedProduct){
            window.alert("Select Product");
            return;
        }
        if (form.number.length === 0){
            window.alert("Enter BOM Number");
            return;
        }
        if (form.length.length === 0){
            window.alert("Enter Length of the product");
            return;
        }
        if (form.width.length === 0){
            window.alert("Enter Width of the product");
            return;
        }
        if (form.product_count.length === 0){
            window.alert("Enter how many products created in this lot?");
            return;
        }
        createBOM(selectedProduct?.id, form.length, form.width, form.number, form.product_count, null).then(res => {
            if (res.success){
                const bomForm = Modal.getOrCreateInstance(document.getElementById('bom-form'));
                bomForm.hide();
                setBOMs([...BOMs, res.bom]);
            }
        })
    }
    return (
        <>
            <div className="modal fade w-100" data-bs-keyboard="false" id="bom-form" tabindex="-1" aria-labelledby="bom-formLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create BOM</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                
                                <div className="form-group">
                                    
                                    {
                                        selectedProduct ?
                                        <>
                                            You have selected {selectedProduct?.name}&nbsp;
                                            <Link to="#" onClick={e => setSelectedProduct(false)} className="text-primary">Change?</Link>
                                        </> :
                                        <>
                                            <ProductSearch selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct}/>
                                        </>
                                    }
                                    
                                </div>
                                {
                                    selectedProduct &&
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="bomNumberInput">BOM Number</label>
                                            <input type="text" id="bomNumberInput" name="bomnumber" onChange={e => setForm({...form, number: e.target.value})} value={form.number} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="bomProductLengthInput">Product Length</label>
                                            <input type="number" id="bomProductLengthInput" name="productLength" onChange={e => setForm({...form, length: e.target.value})} value={form.length} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="bomProductWidthInput">Product Width</label>
                                            <input type="number" id="bomProductWidthInput" name="productWidth" onChange={e => setForm({...form, width: e.target.value})} value={form.width} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="bomProductCountInput">Product count</label>
                                            <input type="number" id="bomProductCountInput" name="productCount" onChange={e => setForm({...form, product_count: e.target.value})} value={form.product_count} className="form-control" />
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light bg-light text-black" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={submit}>Save</button>
                        </div>
                    </div>
                </div>
             </div>
        </>
    )
}

export default connect(null, { getCategories, createBOM })(BOMForm);
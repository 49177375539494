import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccountsDashboard from "../../components/accounts/AccountsDashboard";
import AccountsOrders from "../../components/accounts/AccountsOrders";
import Tabs from "../../components/accounts/Tabs";
import Breadcrumb from "../../components/page/Breadcrumb";
import PageHeader from "../../components/page/PageHeader";
import { useIsAuthenticated } from "../../reducers/accounts/auth";

const MyAccount = () => {
    const { isAuthenticated } = useIsAuthenticated();
    let navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated)
            navigate('/login', {replace: true});
    }, [isAuthenticated]);
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'My Account',
            url: ''
        }
    ]
    const [viewMode, setViewMode] = useState('my-account');
    return (
        <>
            <main className="main">
                <PageHeader>My Account</PageHeader>
                <Breadcrumb items={breadcrumbItems} />

                <div className="page-content pt-2">
                    <div className="container">
                        <div className="tab tab-vertical row gutter-lg">
                            <Tabs viewMode={viewMode} setViewMode={setViewMode} />
                            <div className="tab-content mb-6">
                                { viewMode === 'my-account' && <AccountsDashboard viewMode={viewMode} setViewMode={setViewMode} /> }
                                { viewMode === 'orders' && <AccountsOrders viewMode={viewMode} /> }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default connect(null, {})(MyAccount);
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/accounts/auth";
import { useSelfUser } from "../../reducers/accounts/user";

const AccountsDashboard = ({ viewMode, setViewMode, logout }) => {
    const { user } = useSelfUser();
    const signout = () => {
        logout()
    }
    return (
        <>
            <div className={`tab-pane ${viewMode === 'my-account' ? 'active in' : ''}`} id="account-dashboard">
                <p className="greeting">
                    Hello&nbsp;
                    <span className="text-dark font-weight-bold">{user?.full_name}</span>&nbsp;
                    (not&nbsp;
                    <span className="text-dark font-weight-bold">{user?.full_name}</span>?&nbsp;
                    <Link to="#" onClick={signout} className="text-primary">Log out</Link>)
                </p>

                <p className="mb-4">
                    From your account dashboard you can view your <Link to="#" onClick={e => setViewMode('orders')}
                        className="text-primary link-to-tab">recent orders</Link>,
                    manage your <Link to="#" onClick={e => setViewMode('addresses')} className="text-primary link-to-tab">shipping
                        and billing
                        addresses</Link>, and
                    <Link to="#" onClick={e => setViewMode('account-details')} className="text-primary link-to-tab">edit your password and
                        account details.</Link>
                </p>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                        <Link to="#" onClick={e => setViewMode('orders')} className="link-to-tab">
                            <div className="icon-box text-center">
                                <span className="icon-box-icon icon-orders">
                                    <i className="w-icon-orders"></i>
                                </span>
                                <div className="icon-box-content">
                                    <p className="text-uppercase mb-0">Orders</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                        <Link to="#" onClick={e => setViewMode('addresses')} className="link-to-tab">
                            <div className="icon-box text-center">
                                <span className="icon-box-icon icon-address">
                                    <i className="w-icon-map-marker"></i>
                                </span>
                                <div className="icon-box-content">
                                    <p className="text-uppercase mb-0">Addresses</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                        <Link to="#" onClick={e => setViewMode('account-details')} className="link-to-tab">
                            <div className="icon-box text-center">
                                <span className="icon-box-icon icon-account">
                                    <i className="w-icon-user"></i>
                                </span>
                                <div className="icon-box-content">
                                    <p className="text-uppercase mb-0">Account Details</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                        <Link to="#" onClick={signout}>
                            <div className="icon-box text-center">
                                <span className="icon-box-icon icon-logout">
                                    <i className="w-icon-logout"></i>
                                </span>
                                <div className="icon-box-content">
                                    <p className="text-uppercase mb-0">Logout</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(null, { logout })(AccountsDashboard);
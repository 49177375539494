import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginPopup from "../components/auth/LoginPopup";
import Breadcrumb from "../components/page/Breadcrumb";
import PageHeader from "../components/page/PageHeader";
import { useIsAuthenticated } from "../reducers/accounts/auth";
// import { isAuthenticated } from "../reducers/accounts/auth";

const Signin = () => {
    const { isAuthenticated } = useIsAuthenticated();
    let navigate = useNavigate();
    useEffect(() => {
        if(isAuthenticated)
            navigate('/my-account', {replace: true});
    }, [isAuthenticated]);
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'Signin / Register',
            url: ''
        }
    ]
    return (
        <>
            <main className="main login-page">
                <PageHeader>Signin / Register</PageHeader>
                <Breadcrumb items={breadcrumbItems} />
                <div className="page-content">
                    <div className="container">
                        <LoginPopup />
                    </div>
                </div>
            </main>
        </>
    )
}
export default Signin;
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/page/Breadcrumb";
import PageHeader from "../../components/page/PageHeader";

const BOMTemp = () => {
    const breadcrumbItems = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'BOM Dashboard',
            url: ''
        }
    ]
    return (
        <>
            <main className="main">
                <PageHeader>BOM Dashboard</PageHeader>
                <Breadcrumb items={breadcrumbItems} />
                <div className="page-content pt-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                                <Link to="/bom-dashboard/boms/">
                                    <div className="icon-box text-center">
                                        <span className="icon-box-icon icon-orders">
                                            <i className="w-icon-orders"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <p className="text-uppercase mb-0">BOMs</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                                <Link to="/bom-dashboard/materials/">
                                    <div className="icon-box text-center">
                                        <span className="icon-box-icon icon-orders">
                                            <i className="w-icon-orders"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <p className="text-uppercase mb-0">Materials</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                                <Link to="/bom-dashboard/categories/">
                                    <div className="icon-box text-center">
                                        <span className="icon-box-icon icon-orders">
                                            <i className="w-icon-orders"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <p className="text-uppercase mb-0">Categories</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-4 col-xs-6 mb-4">
                                <Link to="/bom-dashboard/products/">
                                    <div className="icon-box text-center">
                                        <span className="icon-box-icon icon-orders">
                                            <i className="w-icon-orders"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <p className="text-uppercase mb-0">Products</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default connect(null, {})(BOMTemp);
import axios from 'axios';
import Cookies from 'js-cookie';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';

export const createOrder = (order_id) => async dispatch => {
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/orders/razorpay/create-order/${order_id}/`, {}, JSON_CONTENT_FORM_CONFIG);
        return res.data
    }
    catch(err){
        return {
            success: false
        }
    }
};

export const registerPayment = (order_id, amount, razorpay_payment_id, razorpay_order_id, razorpay_signature) => async dispatch => {
    let body = JSON.stringify({amount, razorpay_payment_id, razorpay_order_id, razorpay_signature})
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/orders/razorpay/register-transaction/${order_id}/`, body, JSON_CONTENT_FORM_CONFIG);
        return res.data
    }
    catch(err){
        return {
            success: false
        }
    }
};
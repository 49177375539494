import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../actions/orders/currentOrder";
import { useCart } from "../../reducers/orders/currentOrder";
// import { toast } from "react-toastify";

const AddToCartForm = ({ variant, addToCart }) => {
  const [qty, setQty] = useState(1);
  const { cart } = useCart();
  const addtocart = () => {
    if (variant === null) return;
    addToCart(cart?.id, variant, qty);
    setQty(1);
  };
  return (
    <>
      <div
        className="fix-bottom product-sticky-content sticky-content"
        style={{ marginTop: 20 + "px" }}
      >
        <div className="product-form container">
          <div className="product-qty-form">
            <div className="input-group">
              <input
                className="quantity form-control"
                type="number"
                min="1"
                max="10000000"
                value={qty}
                disabled
              />
              <button
                className="quantity-plus w-icon-plus"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setQty(qty + 1);
                }}
              ></button>
              <button
                className="quantity-minus w-icon-minus"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (qty >= 2) setQty(qty - 1);
                }}
              ></button>
            </div>
          </div>
          {variant === null ? (
            <button className="btn btn-primary btn-cart" disabled>
              <i className="w-icon-cart"></i>
              <span>Add to Cart</span>
            </button>
          ) : (
            <button className="btn btn-primary btn-cart" onClick={addtocart}>
              <i className="w-icon-cart"></i>
              <span>Add to Cart</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default connect(null, { addToCart })(AddToCartForm);

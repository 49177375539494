import axios from 'axios';
import Cookies from 'js-cookie';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';

export const getCategories = () => async dispatch => {
    const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/categories/get/`, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};

export const createCategory = (name) => async dispatch => {
    let body = JSON.stringify({name});
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/categories/create/`, body, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};

export const deleteCategory = (category_id) => async dispatch => {
    const res = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/bomtemp/categories/delete/${category_id}/`, JSON_CONTENT_FORM_CONFIG);
    return res.data;
};
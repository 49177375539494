import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Breadcrumb from "../components/page/Breadcrumb";
import PageHeader from "../components/page/PageHeader";
import ProductItem from "../components/shop/ProductItem";
import { GetProducts } from "../actions/shop/GetProducts";
import { connect } from "react-redux";
import { useCategories } from "../reducers/shop/Categories";
import VarietyFilter from "../components/shop/filters/VarietyFilter";
import CategoryFilter from "../components/shop/filters/CategoryFilter";
import { usePageMeta } from "../reducers/pagemetaReducer";
import { update_page_meta } from "../actions/page/header";

// Shop/Category page
const Shop = ({ update_page_meta }) => {
  const { categories } = useCategories();
  const { categorySlug } = useParams();
  const getCategory = (categoryList) => {
    for (const category of categoryList) {
      if (category.slug === categorySlug) {
        return category;
      }
      if (category.subCategories) {
        const subCategory = getCategory(category.subCategories);
        if (subCategory.id !== undefined) {
          return subCategory;
        }
      }
    }
    return {
      id: undefined,
      category: "Shop",
      slug: undefined,
    };
  };

  const [Category, setCategory] = useState({
    id: undefined,
    category: "Shop",
    slug: undefined,
  });

  useEffect(() => {
    if (categorySlug === undefined)
      setCategory({
        id: undefined,
        category: "Shop",
        slug: undefined,
      });
    if (categories?.length > 0 && categorySlug !== undefined)
      setCategory({ ...getCategory(categories) });
  }, [categories, categorySlug]);

  const [Products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const [fetching, setFetching] = useState(false);
  const bottomElementRef = useRef();

  const fetchMore = async () => {
    if (page > totalPages) return;
    if (categorySlug !== undefined && Category.id === undefined) return;
    try {
      setFetching(true);
      const response = await GetProducts([], page, [Category], perPage);
      if (response.success) {
        setProducts((prevProducts) => {
          if (page === 1) {
            return response.products; // Set the initial products
          } else {
            return [...prevProducts, ...response.products]; // Append new products
          }
        });
        setTotalPages(response.num_pages);
        setPage(page + 1);
      }
    } catch (error) {
      console.error("Error fetching more products:", error);
    } finally {
      setFetching(false);
    }
  };

  const loadMoreContent = () => {
    if (page > totalPages || fetching) return;
    fetchMore();
  };

  useEffect(() => {
    if (page !== 1 || fetching) return;
    fetchMore();
  }, [page]);
  const pageMeta = usePageMeta();
  useEffect(() => {
    setPage(1);
    setTotalPages(1);
    update_page_meta({
      ...pageMeta,
      title: `Buy Best Mattresses Online${
        Category?.id !== undefined ? " | " + Category.category : ""
      } | Apogee Mattress`,
      description:
        "Inspiring a sleep revolution by educating people about the impact of a holistic sleep with support, comfort and luxury",
      opengraph: {},
      twitter: {},
    });
  }, [Category, setPage, setTotalPages, setProducts]);

  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: "0px",
      threshold: 0.1, // When 10% of the element is visible, trigger the callback
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        loadMoreContent();
      }
    }, options);

    if (bottomElementRef.current) {
      observer.observe(bottomElementRef.current);
    }

    return () => {
      if (bottomElementRef.current) {
        observer.unobserve(bottomElementRef.current);
      }
    };
  }, [bottomElementRef, page, totalPages, fetching, fetchMore]);

  const product_items = Products?.map((product) => {
    return <ProductItem product={product} />;
  });
  const breadcrumbItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Shop",
      url: "",
    },
  ];
  return (
    <>
      <main className="main">
        <PageHeader>{Category.category}</PageHeader>
        <Breadcrumb items={breadcrumbItems} />
        <div className="page-content">
          <div className="container">
            {/* Start of Shop Content */}
            <div className="shop-content row gutter-lg mb-10">
              <aside className="sidebar shop-sidebar sticky-sidebar-wrapper sidebar-fixed">
                <div className="sidebar-overlay"></div>
                <Link className="sidebar-close" to="#">
                  <i className="close-icon"></i>
                </Link>

                <div className="sidebar-content scrollable">
                  <div className="sticky-sidebar">
                    <div className="filter-actions">
                      <label>Filter :</label>
                      <a
                        href="#"
                        className="btn btn-dark btn-link filter-clean"
                      >
                        Clean All
                      </a>
                    </div>
                    <CategoryFilter />
                  </div>
                </div>
              </aside>
              {/* End of Shop Sidebar */}

              {/* Start of Shop Main Content */}
              <div className="main-content">
                <nav className="toolbox sticky-toolbox sticky-content fix-top">
                  <div className="toolbox-left">
                    <a
                      href="#"
                      className="btn btn-primary btn-outline btn-rounded left-sidebar-toggle
                                            btn-icon-left d-block d-lg-none"
                    >
                      <i className="w-icon-category"></i>
                      <span>Filters</span>
                    </a>
                    <div className="toolbox-item toolbox-sort select-box text-dark">
                      {/* <label>Sort By :</label>
                                            <select name="orderby" className="form-control">
                                                <option value="default" selected="selected">Default sorting</option>
                                                <option value="popularity">Sort by popularity</option>
                                                <option value="rating">Sort by average rating</option>
                                                <option value="date">Sort by latest</option>
                                                <option value="price-low">Sort by pric: low to high</option>
                                                <option value="price-high">Sort by price: high to low</option>
                                            </select> */}
                    </div>
                  </div>
                  <div className="toolbox-right">
                    {/* <div className="toolbox-item toolbox-show select-box">
                                            <select name="count" value={perPage} onChange={e => setPerPage(e.target.value)} className="form-control">
                                                <option value="9">Show 9</option>
                                                <option value="12" >Show 12</option>
                                                <option value="24">Show 24</option>
                                                <option value="36">Show 36</option>
                                            </select>
                                        </div> */}
                    {/*<div className="toolbox-item toolbox-layout">*/}
                    {/*    <a href="shop-banner-sidebar.html" className="icon-mode-grid btn-layout active">*/}
                    {/*        <i className="w-icon-grid"></i>*/}
                    {/*    </a>*/}
                    {/*    <a href="shop-list.html" className="icon-mode-list btn-layout">*/}
                    {/*        <i className="w-icon-list"></i>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                  </div>
                </nav>

                <div className="product-wrapper row cols-md-3 cols-sm-2 cols-2">
                  {product_items}
                </div>
                {fetching && (
                  <>
                    <div className="d-flex justify-content-center align-content-center">
                      <i
                        className="fa fa-refresh fa-spin fa-2x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </>
                )}
                <div ref={bottomElementRef}></div>
              </div>
              {/* End of Shop Main Content */}
            </div>
            {/* End of Shop Content */}
          </div>
        </div>
      </main>
    </>
  );
};
export default connect(null, { update_page_meta })(Shop);

import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useCategories } from "../reducers/shop/Categories";
import { GetCategories } from "../actions/shop/GetCategories";
import "swiper/css/bundle";
import "react-toastify/dist/ReactToastify.css";
import "./style.min.css";
import { checkAuthenticated } from "../actions/accounts/auth";
import { useIsAuthenticated } from "../reducers/accounts/auth";
import { getSelfUser } from "../actions/accounts/user";
import CSRFToken from "../components/CSRFToken";
import { getCurrentOrder } from "../actions/orders/currentOrder";

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

const Layout = ({
  checkAuthenticated,
  getSelfUser,
  GetCategories,
  getCurrentOrder,
  children,
}) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { categories } = useCategories();
  useEffect(() => {
    checkAuthenticated();
    if (!categories?.length) GetCategories();
  }, []);
  useEffect(() => {
    getCurrentOrder();
    if (isAuthenticated) getSelfUser();
  }, [isAuthenticated]);
  useEffect(() => {
    AddLibrary("/static/assets/vendor/sticky/sticky.min.js");

    AddLibrary("/static/assets/vendor/jqueryPlugin/jquery.plugin.min.js");

    AddLibrary("/static/assets/vendor/owl-carousel/owl.carousel.min.js");

    AddLibrary("/static/assets/vendor/nouislider/nouislider.min.js");

    AddLibrary("/static/assets/vendor/jqueryCountdown/jquery.countdown.min.js");

    AddLibrary("/static/assets/vendor/imagesloaded/imagesloaded.pkgd.min.js");

    AddLibrary("/static/assets/vendor/zoom/jquery.zoom.min.js");

    AddLibrary(
      "/static/assets/vendor/magnific-popup/jquery.magnific-popup.min.js"
    );

    /* AddLibrary('/static/assets/vendor/swiper/swiper-bundle.min.js') */

    /* AddLibrary('/static/assets/js/main.min.js') */

    AddLibrary("/static/assets/js/main.js");
  }, []);

  return (
    <>
      <CSRFToken />
      <Header />
      {children}
      <Footer />
    </>
  );
};
export default connect(null, {
  checkAuthenticated,
  getSelfUser,
  GetCategories,
  getCurrentOrder,
})(Layout);

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/page/Breadcrumb";
import { useCart } from "../reducers/orders/currentOrder";
import { addToCart, setCart } from "../actions/orders/currentOrder";
import { connect } from "react-redux";
import { update_page_meta } from "../actions/page/header";
import { usePageMeta } from "../reducers/pagemetaReducer";

const Cart = ({ addToCart, update_page_meta }) => {
  const pageMeta = usePageMeta();
  useEffect(() => {
    update_page_meta({
      ...pageMeta,
      title: "Cart | Apogee Mattress",
      description:
        "Inspiring a sleep revolution by educating people about the impact of a holistic sleep with support, comfort and luxury",
      opengraph: {},
      twitter: {},
    });
  }, []);
  const { cart } = useCart();
  const breadcrumbItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Shopping Cart",
      url: "",
    },
  ];
  const removefromcart = (e, line) => {
    e.preventDefault();
    e.stopPropagation();
    addToCart(cart?.id, line.product, 0 - line.product.quantity);
  };
  const rows = cart?.lineItems?.map((line) => {
    if (line.quantity === 0) return null;
    else {
      return (
        <tr>
          <td className="product-thumbnail">
            <div className="p-relative">
              <Link
                to={`/shop/${line.product.category.slug}/${line.product.slug}`}
              >
                <figure>
                  <img
                    src={line.product.product_media[0].src}
                    alt={line.product.display_name}
                    width="300"
                    height="338"
                  />
                </figure>
              </Link>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removefromcart(e, line);
                }}
                className="btn btn-close"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </td>
          <td className="product-name">
            <Link
              to={`/shop/${line.product.category.slug}/${line.product.slug}`}
            >
              {line.product.display_name}
            </Link>
          </td>
          <td className="product-price">
            <span className="amount">₹ {line.product.salePrice}</span>
          </td>
          <td className="product-quantity">
            <div className="input-group">
              <input
                className="quantity form-control"
                type="number"
                value={line.quantity}
                min="1"
                max="100000"
              />
              <button
                className="quantity-plus w-icon-plus"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  addToCart(cart?.id, line.product, 1);
                }}
              ></button>
              <button
                className="quantity-minus w-icon-minus"
                style={{ borderRadius: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (line.quantity > 0) addToCart(cart?.id, line.product, -1);
                }}
              ></button>
            </div>
          </td>
          <td className="product-subtotal">
            <span className="amount">
              ₹ {line.quantity * line.product.salePrice}
            </span>
          </td>
        </tr>
      );
    }
  });
  return (
    <>
      <main className="main cart">
        {/* Start of Breadcrumb */}
        {/* <Breadcrumb items={breadcrumbItems} styles={{paddingBottom: 0 + "px !important", marginBottom: 0 + "px !important"}} /> */}
        <nav className="breadcrumb-nav">
          <div className="container">
            <ul className="breadcrumb shop-breadcrumb bb-no">
              <li className="active">
                <Link to="/cart">Shopping Cart</Link>
              </li>
              <li>
                <Link
                  to="/checkout"
                  className={`${
                    cart?.lineItems?.length === 0 ? "disabled" : ""
                  }`}
                >
                  Checkout
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        {/* End of Breadcrumb */}

        {/* Start of PageContent */}
        <div className="page-content">
          <div className="container">
            <div className="row gutter-lg mb-10">
              <div className="col-lg-8 pr-lg-4 mb-6">
                <table className="shop-table cart-table">
                  <thead>
                    <tr>
                      <th className="product-name">
                        <span>Product</span>
                      </th>
                      <th></th>
                      <th className="product-price">
                        <span>Price</span>
                      </th>
                      <th className="product-quantity">
                        <span>Quantity</span>
                      </th>
                      <th className="product-subtotal">
                        <span>Subtotal</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>

                <div className="cart-action mb-6">
                  <Link
                    to="/shop"
                    className="btn btn-dark btn-rounded btn-icon-left btn-shopping mr-auto"
                  >
                    <i className="w-icon-long-arrow-left"></i>Continue Shopping
                  </Link>
                  {/* <button
                    type="submit"
                    className="btn btn-rounded btn-default btn-clear"
                    name="clear_cart"
                    value="Clear Cart"
                  >
                    Clear Cart
                  </button> */}
                  <button
                    type="submit"
                    className="btn btn-rounded btn-update disabled"
                    name="update_cart"
                    value="Update Cart"
                  >
                    Update Cart
                  </button>
                </div>

                <form className="coupon">
                  <h5 className="title coupon-title font-weight-bold text-uppercase">
                    Coupon Discount
                  </h5>
                  <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Enter coupon code here..."
                    required
                  />
                  <button className="btn btn-dark btn-outline btn-rounded">
                    Apply Coupon
                  </button>
                </form>
              </div>
              <div className="col-lg-4 sticky-sidebar-wrapper">
                <div className="sticky-sidebar">
                  <div className="cart-summary mb-4">
                    <h3 className="cart-title text-uppercase">Cart Totals</h3>
                    {/* <div className="cart-subtotal d-flex align-items-center justify-content-between">
                      <label className="ls-25">Total</label>
                      <span>₹ {cart?.amount_payable}</span>
                    </div> */}

                    <hr className="divider" />

                    {/* <ul className="shipping-methods mb-2">
                      <li>
                        <label className="shipping-title text-dark font-weight-bold">
                          Shipping
                        </label>
                      </li>
                      <li>
                        <div className="custom-radio">
                          <input
                            type="radio"
                            id="free-shipping"
                            className="custom-control-input"
                            name="shipping"
                          />
                          <label
                            for="free-shipping"
                            className="custom-control-label color-dark"
                          >
                            Free Shipping
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-radio">
                          <input
                            type="radio"
                            id="local-pickup"
                            className="custom-control-input"
                            name="shipping"
                          />
                          <label
                            for="local-pickup"
                            className="custom-control-label color-dark"
                          >
                            Local Pickup
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-radio">
                          <input
                            type="radio"
                            id="flat-rate"
                            className="custom-control-input"
                            name="shipping"
                          />
                          <label
                            for="flat-rate"
                            className="custom-control-label color-dark"
                          >
                            Flat rate: ₹100.00
                          </label>
                        </div>
                      </li>
                    </ul> */}

                    {/* <div className="shipping-calculator">
                      <p className="shipping-destination lh-1">
                        Shipping to <strong>India</strong>.
                      </p>

                      <form className="shipping-calculator-form">
                        <div className="form-group">
                          <div className="select-box">
                            <select
                              name="state"
                              className="form-control form-control-md"
                            >
                              <option value="Odisha" selected="selected">
                                Odisha
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="West Bengal">West Bengal</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control form-control-md"
                            type="text"
                            name="town-city"
                            placeholder="Town / City"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control form-control-md"
                            type="text"
                            name="zipcode"
                            placeholder="ZIP"
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-dark btn-outline btn-rounded"
                        >
                          Update Totals
                        </button>
                      </form>
                    </div> */}

                    <hr className="divider mb-6" />
                    <div className="order-total d-flex justify-content-between align-items-center">
                      <label>Total</label>
                      <span className="ls-50">₹ {cart?.amount_payable}</span>
                    </div>
                    <Link
                      to="/checkout"
                      className={`btn btn-block btn-dark btn-icon-right btn-rounded  btn-checkout ${
                        cart?.lineItems?.length === 0 ? "disabled" : ""
                      }`}
                    >
                      Proceed to checkout
                      <i className="w-icon-long-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End of PageContent */}
      </main>
    </>
  );
};

export default connect(null, { addToCart, update_page_meta })(Cart);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { detailProduct, removeBOMLine } from "../../actions/bomtemp/products";
import BOMLineForm from "../../components/bomtemp/BOMLineForm";
import Breadcrumb from "../../components/page/Breadcrumb";
import PageHeader from "../../components/page/PageHeader";

const ProductDetail = ({ detailProduct, removeBOMLine }) => {
    const { product_id } = useParams();
    const [Product, setProduct] = useState(false);
    useEffect(() => {
        detailProduct(product_id).then(res => {
            if (res.success){
                setProduct(res.product);
            }
        })
    }, [product_id]);
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'BOM Dashboard',
            url: '/bom-dashboard'
        },
        {
            title: 'Products',
            url: '/bom-dashboard/products'
        },
        {
            title: product_id,
            url: ''
        }
    ]);
    useEffect(() => {
        if (Product){
            breadcrumbItems.splice(3, 1, {title: Product?.name, url: ''})
            setBreadcrumbItems([...breadcrumbItems])
        }
    }, [Product]);
    const deleteline = (e, line) => {
        if (!window.confirm("You are about to remove a material from this BOQ. Are you sure?"))
            return;
        removeBOMLine(line?.id).then(res => {
            if (res.success){
                var lines = Product?.lines.filter(item => item.id !== line.id);
                setProduct({...Product, lines: lines});
            }
        })
    }
    const rows = Product?.lines?.map(line => {
        return (
            <tr key={line.id}>
                <td>{line.material.name}</td>
                <td>{line.quantity}</td>
                <td><i className="fa fa-trash cursor-pointer text-danger" onClick={e => deleteline(e, line)}></i></td>
            </tr>
        )
    })
    return (
        <>
            <main className="main">
                <PageHeader>{Product?.name}</PageHeader>
                <Breadcrumb items={breadcrumbItems} />
                <div className="page-content pt-2">
                    <div className="container">
                        <div className="text-end">
                            <button data-bs-toggle="modal" data-bs-target="#bom-line-form" className="btn btn-primary">
                                Add Line
                            </button> 
                        </div>
                        <div className="mt-2 mb-2 table-responsive">
                            <table className="table">
                                <thead>
                                    <th>Material</th>
                                    <th>Quantity</th>
                                    <th></th>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
            <BOMLineForm Product={Product} setProduct={setProduct} />
        </>
    )
}

export default connect(null, { detailProduct, removeBOMLine })(ProductDetail);
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MyAccount from '../containers/accounts/MyAccount';


export default class AccountRoute extends React.Component{
    render(){
        return (
            <Routes>
                <Route path='/my-account/' element={<MyAccount/>} />
                <Route path='/my-account/orders/' element={<MyAccount/>} />
                <Route path='/my-account/addresses/' element={<MyAccount/>} />
                <Route path='/my-account/orders/' element={<MyAccount/>} />
            </Routes>
        )
    }
}
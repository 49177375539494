import axios from 'axios';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';
import { GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAIL } from './types';

export const GetCategories = () => async dispatch => {
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/catalog/get-categories/`, JSON_CONTENT_FORM_CONFIG);
        if (res.data.success){
            dispatch({
                type: GET_CATEGORIES_SUCCESS,
                payload: res.data.categories
            });
        }
        else{
            dispatch({
                type: GET_CATEGORIES_FAIL
            });
        }
    }
    catch(err){
        dispatch({
            type: GET_CATEGORIES_FAIL
        });
    }
};

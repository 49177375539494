import axios from 'axios';
import { JSON_CONTENT_FORM_CONFIG } from '../Config';
import { GET_VARIETIES_SUCCESS, GET_VARIETIES_FAIL } from './types';

export const GetVarieties = () => async dispatch => {
    try{
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/references/get-varieties/`, JSON_CONTENT_FORM_CONFIG);
        if (res.data.success){
            dispatch({
                type: GET_VARIETIES_SUCCESS,
                payload: res.data.varieties
            });
        }
        else{
            dispatch({
                type: GET_VARIETIES_FAIL
            });
        }
    }
    catch(err){
        dispatch({
            type: GET_VARIETIES_FAIL
        });
    }
};
